<template>
  <div class="basket-menu">
    <router-link to="/basket">
      <img src="../assets/images/icons/icon-basket.svg" alt="basket">
    </router-link>
    <span class="quantity" v-if="getAmountOfProducts">{{ getAmountOfProducts }}</span>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    computed: {
      ...mapGetters(['getAmountOfProducts']),
    },

    created() {
      this.$store.dispatch('fetchBasket')
    }
  }
</script>

<style lang="scss" scoped>
  .basket-menu {
    width: 20px;
    position: relative;
  }

  .quantity {
    display: block;
    font: 400 12px $roboto;
    color: #F9F9F9;
    position: absolute;
    right: -6px;
    top: -8px;
  }
</style>