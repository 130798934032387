<template>
  <div class="login-page">
    <div class="container-login">
      <div class="login-logo">
        <img class="login-logo__left" src="../assets/images/logo_megabonus.svg" alt="logo">
        <div class="login-logo__right">
          <img class="login-logo__left" src="../assets/images/logo-fit-service-small.svg" alt="logo">
          <div class="login-logo__txt">Программа лояльности</div>
        </div>
      </div>
      <div :class="{hide: isVisible}" class="login-top">  
        <div class="attention">
          <div class="attention__icon"><span></span></div>
          <div class="attention__text">Только для сотрудников<em>FIT SERVICE</em></div>
        </div>
        <div class="login-start-text">Чтобы начать, необходимо войти, подтвердив свой номер телефона</div>

        <div class="disclaimer">
          <label class="agree">
            <input type="checkbox" v-model="agreed">
            <span class="checkmark"></span>
          </label>
          <p v-if="!showInfo">{{longText.slice(0, 210)}}</p>
          <a v-if="!showInfo" @click="toggleShowInfo" href="#">...</a>
          <p v-if="showInfo" @click="toggleShowInfo" v-html="longText"></p>
        </div>
      </div>
      <div v-if="smsError || loginError" class="login-error">
        <em  v-if="loginError" class="login-error__msg">Неверный номер</em>
        <em v-if="smsError" class="login-error__msg">Неверный код смс</em>
        <div class="login-error__txt">
          Если вы являетесь сотрудником FIT SERVICE и у вас возникли проблемы со входом, обратитесь в техподдержку <br> <a href="mailto:fithelp1c@fitauto.ru">fithelp1c@fitauto.ru</a>
        </div>
      </div>
      <form v-if="!userData" class="login-form" @submit.prevent="login">
        <label :class="{active: inputTelActive}" class="login-form__label" for="login-input">Номер телефона</label>
        <div class="login-input-wrap">
          <input 
            v-model="tel"
            :class="{active: inputTelActive}"
            @focus="hide"
            :disabled="!agreed"
            id="login-input"  
            class="login-form__input" 
            type="text"
            v-mask="'+7 (###)-###-####'"
            placeholder="+7 (___) ___-__-__">
          <span class="login-form__clear" @click="clearInput"></span>
          <button class="login-form__btn" type="submit" :disabled="!phoneLength">Подтвердить номер</button>
        </div>
      </form>

      <form v-if="userData" class="login-form" @submit.prevent="checkSMS">
        <label class="login-form__label" for="login-input">
        <span>
          Пароль из СМС
        </span>
        <span v-if="counting">
          {{convertSeconds(count)}}
        </span>
        </label>
        
        <div class="login-input-wrap">
          <input 
            v-model="smsCode" 
            :class="{active: inputSmsActive}"
            @focus="hide"
            id="login-input" 
            class="login-form__input" 
            type="text" 
            v-mask="'####'"
            placeholder="пароль из смс">
          <span class="login-form__clear" @click="clearInput"></span>

          <a v-if="!counting" class="login-form__resend" @click="resendSms">Отправить смс повторно</a>

          <button class="login-form__btn" type="submit" :disabled="!smsLength">Подтвердить смс</button>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        tel: "",
        smsCode: "",
        error: null,
        isVisible: false,
        showInfo: false,
        agreed: false,
        counting: false,
        timerId: "",
        count: 300,
        countFrom: 300,
        longText: `Авторизуясь на данном сайте, Пользователь тем самым даёт согласие на обработку,
        хранение и использование своих персональных данных на основании ФЗ № 152-ФЗ «О персональных данных»
        от 27.07.2006г. в следующих целях: осуществление клиентской поддержки; получения Пользователем
        информации о маркетинговых событиях, в том числе с помощью смс-мессенджера и иных приложений для
        обмена сообщениями; проведения аудита и прочих внутренних исследований с целью повышения качества
        предоставляемых услуг. Под персональными данными подразумевается любая информация личного характера,
        предоставленная Пользователем и позволяющая установить его личность.`
      }
    },

    computed: {
      ...mapState({
        loginError: state => state.auth.loginError,
        smsError: state => state.auth.smsError,
        userData: state => state.auth.userData,
      }),

      phoneLength() {
        return this.tel.length >= 14 ? true : false
      },

      smsLength() {
        return this.smsCode.length >= 4 ? true : false
      },

      inputTelActive() {
        return this.tel.length > 0 ? true : false
      },

      inputSmsActive() {
        return this.smsCode.length > 0 ? true : false
      }
    },

    methods: {
      login() {
        this.$store.dispatch('login', {
          tel: this.cleanNumber(this.tel)
        })
        this.startCounter();
      },

      checkSMS() {
        this.$store.dispatch('checkSMS', {
          code: this.smsCode
        })
      },

      resendSms() {
        this.login()
      },

      clearInput() {
        this.tel = ""
        this.smsCode = ""
      },

      hide() {
        this.isVisible = true
      },

      cleanNumber(num) {
        return num.replace(/^\+7/,'').replace(/[^\w!?]/g,'');
      },

      convertSeconds(timer) {
        let minutes = parseInt(timer / 60)
        let seconds = timer - minutes * 60;
        let mZero = minutes < 10 ? '0' : '';
        let sZero = seconds < 10 ? '0' : '';
        return `${mZero}${minutes}:${sZero}${seconds}`
      },

      startCounter(){
        if (this.timerId)
          clearInterval(this.timerId);
        
        this.count = this.countFrom
        this.counting = true

        this.timerId = setInterval(function(){
          this.count--;
          if( this.count === 0 ) {
            clearInterval(this.timerId);
            this.count = this.countFrom;
            this.counting = false;
          }
        }.bind(this), 1200);
      },

      toggleShowInfo() {
        this.showInfo = !this.showInfo;
      }
    },
  }
</script>

<style lang="scss" scoped>
  .login-page {
    min-height: 100vh;
    background-color: #1A1C1C; 
    background-image: url('../assets/images/bg/grid.svg');
    background-position: center;
    position: relative;
    overflow: hidden;
  }

  .container-login {
    max-width: 290px;
    margin: 0 auto;
    @include display-less(mediaPhone) {
      max-width: 100%;
      padding:0 20px;
    }
  }

  .login-logo {
    padding-top: 55px;
    display: flex;
    align-items: flex-start;
    align-items: stretch;
    @include display-less(tablet) {
      padding-top: 105px;
    }
    @include display-less(mediaPhone) {
      padding-top: 45px;
    }
    
    &__left {
      width: 100px;
      height: 53px;
    }

    &__right {
      border-left: 1px solid #FF9C43;
      padding-left: 25px;
      margin-left: 25px;
      position: relative;
      img {
        width: 123px;
        height: 26px;
        position: relative;
        top: -6px;
      }
    }

    &__txt {
      color: #F9F9F9;
      font: 400 14px/16px $roboto;
      position: absolute;
      bottom: -1px;
    }
  }

  a {
    color: #FF9C43;
  }

  .attention {
    display: flex;
    align-items: center;
    min-height: 70px;
    background: #3F3F3F;
    border: 1px solid #575757;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 33px;

    &__icon {
      width: 67px;
      text-align: center;
      span {
        display: inline-block;
        width: 37px;
        height: 34px;
        background-image: url('../assets/images/icons/danger.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    &__text {
      font: 400 16px/19px $roboto;
      color: #F9F9F9;
      padding: 15px 15px 15px 0;
      em {
        display: block;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }

  .login-start-text {
    color: #F9F9F9;
    margin-top: 30px;
    font: 300 16px/19px $roboto;
  }

  .login-input-wrap {
    position: relative;
  }

  .login-form {
    position: relative;
    margin-top: 30px;

    &__label {
      display: flex;
      justify-content: space-between;
      font: 400 14px/16px $roboto;
      color: #F9F9F9;
      margin-bottom: 10px;
      &.active {
        color: #FF9C43;
      }
    }
    
    &__input {
      width: 100%;
      height: 40px;
      background: #1A1C1C;
      border: 1px solid #5A5A5A;
      border-radius: 4px;
      padding: 0 30px 0 15px;
      color: #fff;
      font: 300 16px/40px $roboto;
      text-transform: uppercase;
      transition: background .2s;
      &::-webkit-input-placeholder {color: $grey-font; text-transform: lowercase;}
      &::-moz-placeholder          {color: $grey-font; text-transform: lowercase;}
      &:-moz-placeholder           {color: $grey-font; text-transform: lowercase;}
      &:-ms-input-placeholder      {color: $grey-font; text-transform: lowercase;}
      &:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
      &:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
      &:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
      &:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}

      &.active {
        border: 1px solid #FF9C43
      }
    }
    &__clear {
      display: none;
      position: absolute;
      right: 20px;
      top: 15px;
      width: 10px;
      height: 10px;
      background-image: url('../assets/images/icons/clear.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 100;
    }


    &__btn {
      display: block;
      width: 100%;
      height: 50px;
      background: #FF9C43;
      border: 1px solid #1A1C1C;
      border-radius: 4px;
      font: 700 18px/48px $roboto;
      color: #1A1C1C;
      text-align: center;
      margin-top: 44px;
      cursor: pointer;
      padding: 0;
    }
    &:disabled, &__btn[disabled] {
      background: #5A5A5A;
    }

    &__resend {
      display: block;
      margin-top: 20px;
      color: #FF9C43;
      cursor: pointer;
      font: 400 14px/16px "Roboto", sans-serif;
    }
  }
  
  .login-form__input.active + .login-form__clear {
    display: block!important;
  }

  .login-error {
    margin-top: 33px;

    &__msg {
      font: 300 18px/21px $roboto;
      color: #FF5656;
    }

    &__txt {
      font: 300 18px/21px $roboto;
      color: #BDBDBD;
      margin-top: 15px;
    }

    &__contacts {
      font: 400 16px/20px $roboto;
      color: #FFFEFA;
      margin-top: 20px;
    }
  }

  .disclaimer {
    color: #fff;
    font: 300 16px/19px "Roboto", sans-serif;
    display: inline;
    p {
      text-indent: 22px;
    }
  }

  /* Customize the label (the container) */
  .agree {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .agree input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    border: solid 1px;
  }

  /* On mouse-over, add a grey background color */
  .agree:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .agree input:checked ~ .checkmark {
    background-color: #fa9c43;
    border: none;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .agree input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .agree .checkmark:after {
    left: 5px;
    top: 2px;
    width: 2px;
    height: 6px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

</style>