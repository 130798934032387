/**
* Преобразует номер телефона в форматированную строку.
*
* @param {string} num - Номер телефона для преобразования.
* @return {string} Форматированный номер телефона.
*/

const convertPhone = (num) => {
  //очистка от не-чисел
  num = num.replace(/\D/g,'').toString();
  const rus = num.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/); // 913 999 99 99
  const ino = num.match(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/); // 380 99 999 9999

  //возвращаем форматированный номер
  if (ino) {
    num = ['(', ino[1], ') ', ino[2], '-', ino[3], '-', ino[4], ino[5]].join('')
  }
  else if (rus) {
    num = ['+7 (', rus[1], ') ', rus[2], '-', rus[3], '-', rus[4]].join('')
  }
  return num
}

export default convertPhone
