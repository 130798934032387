export default {
  data: [
    {
      "id": 1,
      "name": "MANN",
      "startDate": "2019-02-10",
      "endDate": "2020-03-22",
      "description": "Баллы начисляются за каждый проданный салонный или воздушный фильтр бреда MANN-FILTER 1 фильтр / 20 баллов",
      "video_link": "https://www.youtube.com/embed/lL_EjiTrby8",
      "fee": 20,
      "order_document": "MANN",
      "logo_url": "logo-febi.svg",
      "createdAt": "2020-02-25T10:05:34.809Z",
      "updatedAt": "2020-02-25T10:07:48.411Z",
      "for": "руководителей",
      "from": "стратегических партнеров",
    },

     {
       "id": 2,
       "name": "MANN",
       "startDate": "2020-02-10",
       "endDate": "2020-02-15",
       "description": "Баллы начисляются за каждый проданный салонный или воздушный фильтр бреда MANN-FILTER 1 фильтр / 20 баллов",
       "video_link": "https://www.youtube.com/embed/lL_EjiTrby8",
       "fee": 20,
       "order_document": "MANN",
       "logo_url": "logo-febi.svg",
       "createdAt": "2020-02-25T10:05:34.809Z",
       "updatedAt": "2020-02-25T10:07:48.411Z",
       "for": "руководителей",
       "from": "стратегических партнеров",
     },

    {
      "id": 3,
      "name": "MANN",
      "startDate": "2020-02-10",
      "endDate": "",
      "description": "Баллы начисляются за каждый проданный салонный или воздушный фильтр бреда MANN-FILTER 1 фильтр / 20 баллов",
      "video_link": "https://www.youtube.com/embed/3wJDiw-Sg-Y",
      "fee": 20,
      "order_document": "MANN",
      "logo_url": "logo-febi.svg",
      "createdAt": "2020-02-25T10:05:34.809Z",
      "updatedAt": "2020-02-25T10:07:48.411Z",
      "for": "мастеров-приемщиков",
      "from": "стратегических партнеров", 
    },

    {
      "id": 4,
      "name": "MANN",
      "startDate": "2020-01-10",
      "endDate": "2020-03-10",
      "description": "Баллы начисляются за каждый проданный салонный или воздушный фильтр бреда MANN-FILTER 1 фильтр / 20 баллов",
      "video_link": "https://www.youtube.com/embed/lL_EjiTrby8",
      "fee": 20,
      "order_document": "MANN",
      "logo_url": "logo-febi.svg",
      "createdAt": "2020-02-25T10:05:34.809Z",
      "updatedAt": "2020-02-25T10:07:48.411Z",
      "for": "мастеров-приемщиков",
      "from": "УК FitService",
    },

    {
      "id": 5,
      "name": "MANN fdfdf",
      "startDate": "2020-03-10",
      "endDate": "2020-03-20",
      "description": "Баллы начисляются за каждый проданный салонный или воздушный фильтр бреда MANN-FILTER 1 фильтр / 20 баллов",
      "video_link": "https://www.youtube.com/embed/lL_EjiTrby8",
      "fee": 20,
      "order_document": "MANN",
      "logo_url": "logo-febi.svg",
      "createdAt": "2020-02-25T10:05:34.809Z",
      "updatedAt": "2020-02-25T10:07:48.411Z",
      "for": "мастеров-приемщиков",
      "from": "стратегических партнеров",
    },

    {
      "id": 6,
      "name": "MANN",
      "startDate": "2020-01-10",
      "endDate": "2020-02-10",
      "description": "Баллы начисляются за каждый проданный салонный или воздушный фильтр бреда MANN-FILTER 1 фильтр / 20 баллов",
      "video_link": "https://www.youtube.com/embed/lL_EjiTrby8",
      "fee": 20,
      "order_document": "MANN",
      "logo_url": "logo-febi.svg",
      "createdAt": "2020-02-25T10:05:34.809Z",
      "updatedAt": "2020-02-25T10:07:48.411Z",
      "for": "автомехаников",
      "from": "УК FitService",
    },

    {
      "id": 7,
      "name": "MANN",
      "startDate": "2020-02-10",
      "endDate": "2020-03-10",
      "description": "Баллы начисляются за каждый проданный салонный или воздушный фильтр бреда MANN-FILTER 1 фильтр / 20 баллов",
      "video_link": "https://www.youtube.com/embed/lL_EjiTrby8",
      "fee": 20,
      "order_document": "MANN",
      "logo_url": "logo-febi.svg",
      "createdAt": "2020-02-25T10:05:34.809Z",
      "updatedAt": "2020-02-25T10:07:48.411Z",
      "for": "автомехаников",
      "from": "УК FitService",
    },

    {
      "id": 8,
      "name": "MANN",
      "startDate": "2020-02-10",
      "endDate": "2020-05-10",
      "description": "Баллы начисляются за каждый проданный салонный или воздушный фильтр бреда MANN-FILTER 1 фильтр / 20 баллов",
      "video_link": "https://www.youtube.com/embed/lL_EjiTrby8",
      "fee": 20,
      "order_document": "MANN",
      "logo_url": "logo-febi.svg",
      "createdAt": "2020-02-25T10:05:34.809Z",
      "updatedAt": "2020-02-25T10:07:48.411Z",
      "for": "руководителей",
      "from": "УК FitService",
    },

    {
      "id": 9,
      "name": "MANN",
      "startDate": "2018-02-10",
      "endDate": "2020-02-10",
      "description": "Баллы начисляются за каждый проданный салонный или воздушный фильтр бреда MANN-FILTER 1 фильтр / 20 баллов",
      "video_link": "https://www.youtube.com/embed/lL_EjiTrby8",
      "fee": 20,
      "order_document": "MANN",
      "logo_url": "logo-febi.svg",
      "createdAt": "2020-02-25T10:05:34.809Z",
      "updatedAt": "2020-02-25T10:07:48.411Z",
      "for": "автомехаников",
      "from": "УК FitService",
    },

    {
      "id": 10,
      "name": "MANN",
      "startDate": "2020-02-10",
      "endDate": "2020-08-10",
      "description": "Баллы начисляются за каждый проданный салонный или воздушный фильтр бреда MANN-FILTER 1 фильтр / 20 баллов",
      "video_link": "https://www.youtube.com/embed/lL_EjiTrby8",
      "fee": 20,
      "order_document": "MANN",
      "logo_url": "logo-febi.svg",
      "createdAt": "2020-02-25T10:05:34.809Z",
      "updatedAt": "2020-02-25T10:07:48.411Z",
      "for": "автомехаников",
      "from": "УК FitService"
    }
  ]
}