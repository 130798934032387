<template>
  <div class="task">
    <div class="tasks-main">
      <span @click="back()" class="back"></span>
      <div class="container">
        <h1 class="tasks-heading">Задания</h1>
        <div class="filter-nav">
          <div class="filter-nav__block for">
            <span class="filter-nav__name">Для</span>
            <ul class="f-nav" v-for="(item, idx) in filterForData.data" :key="idx">
              <li :class="{active: idx === filterForData.activeItem}" class="f-nav__item" @click="filterBy('for', item, idx)">{{ item.name }}</li>
            </ul>
          </div>
          <div class="filter-nav__block from">
            <span class="filter-nav__name">От</span>
            <ul class="f-nav" v-for="(item, idx) in filterFromData.data" :key="idx">
              <li :class="{active: idx === filterFromData.activeItem}" class="f-nav__item" @click="filterBy('from', item, idx)">{{ item.name }}</li>
            </ul>
          </div>
          <div class="filter-nav__block show">
            <span class="filter-nav__name">Показать</span>
            <ul class="f-nav" v-for="(item, idx) in filterShowData.data" :key="idx">
              <li :class="{active: idx === filterShowData.activeItem}" class="f-nav__item" @click="filterBy('show', item, idx)">{{ item }}</li>
            </ul>
          </div>
        </div>
        <masonry
          :cols="{default: 3, 960: 2, 600: 1}"
          :gutter="30"
          >
          <div v-for="(item, index) in sortedAndFiltered" :key="index">
            <Task :item="item" />
          </div>
        </masonry>
      </div>
    </div>
  </div>
</template>


<script>
  import { mapState } from 'vuex'
  import Task from '@/components/Task'
  import moment from 'moment'
  export default {

  components: {
    Task
  },

  data() {
    return {
      filteredTasks: [],

      filterForData: {
        data: [],
        activeItem: 1
      },
      filterForKey: {},
      
      filterFromData: {
        data: [{id:0, name:'всех'}],
        activeItem: 0
      },
      filterFromKey: {},

      
      filterShowData: {
        data: ['все','активные','завершенные'],
        activeItem: 1
      },
      filterShowKey: 'активные',

      isActive: false
    }
  },

  created() {
    this.$store.dispatch('fetchTasks')
    this.$store.dispatch('fetchUserRoles')
    this.$store.dispatch('fetchOrganizations')
  },

  computed: {
    ...mapState({
      tasks: state => state.tasks.tasks,
      userRoles: state => state.tasks.userRoles,
      organizations: state => state.tasks.organizations,
    }),

    filtered() {
      let filteredTasks = this.tasks;
      const today = this.toTimestamp(moment().format('YYYY-MM-DD'))

      if(this.filterForKey.id !== 0) {
        filteredTasks = filteredTasks.filter( item => {
          return (item.forRoleId === this.filterForKey.id)
        })
      }

      if(this.filterFromKey.id !== 0) {
        filteredTasks = filteredTasks.filter( item => {
          return (item.fromOrganizationId === this.filterFromKey.id)
        })
      }

      if(this.filterShowKey === 'активные') {
        return filteredTasks.filter( item => {
          return (this.toTimestamp(item.endDate) >= today) || item.unlimited ;
        })
      }

      if(this.filterShowKey === 'завершенные') {
        return filteredTasks.filter( item => {
          return this.toTimestamp(item.endDate) < today
        })
      }

      return filteredTasks
    },

    sortedAndFiltered() {
      const today = this.toTimestamp(moment().format('YYYY-MM-DD'))
      this.filtered.forEach(item => {
        if(this.toTimestamp(item.endDate) >= today){
          item.sortValue = 10
        }
        if(item.unlimited){
          item.sortValue = 7
        }
        if(this.toTimestamp(item.startDate) > today){
          item.sortValue = 5
        }
        if(this.toTimestamp(item.endDate) < today){
          item.sortValue = 0
        }
        
      })
      return this.filtered.slice().sort((a, b) => {
        return b.sortValue - a.sortValue
      })
    }
  },

  watch: {
    userRoles() {
      this.filterForData.data = this.userRoles
      this.filterForKey = this.filterForData.data[this.filterForData.activeItem]
    },

    organizations() {
      this.filterFromData.data = this.filterFromData.data.concat(this.organizations)
      this.filterFromKey = this.filterFromData.data[this.filterFromData.activeItem]
    }
  },

  methods: {
    filterBy(filterName, filterVal, idx) {
      if(filterName === "for") {
        this.filterForKey = filterVal
        this.filterForData.activeItem = idx
      }
      if(filterName === "from") {
        this.filterFromKey = filterVal
        this.filterFromData.activeItem = idx
      }
      if(filterName === "show") {
        this.filterShowKey = filterVal
        this.filterShowData.activeItem = idx
      }
    },

    back() {
      this.$router.go(-1)
    },

    toTimestamp(strDate){
      let datum = Date.parse(strDate)
      return datum/1000;
    }
  },



}
</script>

<style lang="scss" scoped>
  .col {
    width: 33.33%;
    @include display-less(desktop) {
      width: 50%;
    }
    @include display-less(phablet) {
      width: 100%;
    }
  }

  .tasks-main {
    background: #1A1C1C;
    position: relative;
    z-index: 1;
    padding-bottom: 80px;
    min-height: 100vh;
  }

  .tasks-heading {
    font: 200 22px/26px $roboto;
    color: #FFFEFA;
    padding-top: 38px;
    margin-bottom: 40px;
    text-align: center;
  }

  .back {
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    right: 15px;
    top: 15px;
    background-image: url('../assets/images/icons/close-menu.svg');
    cursor: pointer;
  }

  .filter-nav {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    padding-top: 30px;
    justify-content: space-between;
    @include display-less(desktop) {
      margin: 0 -10px;
    }
    @include display-less(phablet) {
      flex-direction: column;
    }

    &__block {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 20px;
      @include display-less(desktop) {
        padding: 0 10px;
        width: 100%!important;
      }
    }

    &__name {
      display: block;
      font: 300 16px/1 $roboto;
      color: #C4C4C4;
      margin-right: 10px;
      z-index: 0;
      @include display-less(desktop) {
        left: 20px!important;
      }
    }
  }
  
  .f-nav {
    display: flex;
    align-items: center;

    &__item {
      font: 300 16px/1 $roboto;
      color: #C4C4C4;
      margin-right: 10px;
      cursor: pointer;
      @include display-less(phablet) {
        font: 300 13px/1 $roboto;
      }
    }
  }

  .f-nav__item.active {
    color: #ff9c43;
  }

  .filter-nav__block.for {
    width: 55%;
    @include display-less(desktop) {
      margin-right:0;
      width: 100%;
    }

    .filter-nav__name {
      left: 10px;
      top: 14px;
    }
  }

  .filter-nav__block.from {
    width: 45%;
    display: flex;
    justify-content: flex-end;
    @include display-less(desktop) {
      width: 100%;
      justify-content: flex-start;
    }

    .filter-nav__name {
      left: 10px;
      top: 14px;
    }
  }

  .filter-nav__block.show {
    width: 50%;
    @include display-less(desktop) {
      width: 100%;
      margin-left: 0px;
    }

    .filter-nav__name {
      left: 10px;
      top: 14px;
    }
  }
</style>