<template>
  <div>
    <div :class="menuClicked == 'catalog' ? 'active' : ''" class="bottom-submenu">
      <BottomMenuCatalog />
    </div>
    <div :class="menuClicked == 'cabinet' ? 'active' : ''" class="bottom-submenu">
      <BottomMenuCabinet />
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/main.js';
  import BottomMenuCabinet from '@/components/BottomMenu/BottomMenuCabinet'
  import BottomMenuCatalog from '@/components/BottomMenu/BottomMenuCatalog'
  export default {
    data() {
      return {
        menuClicked: null,
      }
    },

    created() {
      EventBus.$on('bottomMenuClicked', params => {
        this.menuClicked = params.menuClicked
      });

      EventBus.$on('submenuClose', params => {
        this.menuClicked = params.submenuClose
      });
    },

    components: { 
      BottomMenuCabinet,
      BottomMenuCatalog
    }
  }
  
</script>

<style lang="scss" scoped>
  .bottom-submenu {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: -203px;
    background: #232525;
    width: 100%;
    height: 184px;
    z-index: 500;
    transition: bottom .3s;
    &.active {
      bottom: 0px;
      display: block;
    }
  }
  .slick-slider {
    position: relative;
    display: block;

  }
</style>