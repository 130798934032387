<template>
  <header :class="{ mSearchActive: searchActive }">
    <router-link to="/home" class="header-logo">
      <img src="../assets/images/logo_megabonus.svg" alt="logo">
    </router-link>
    <nav class="nav-wrap">
      <ul class="nav">
        <li class="nav__item" @click="sideMenu('catalog')"><a>Каталог</a></li>
        <li class="nav__item"><router-link to="/instructions">Инструктаж</router-link> </li>
        <li class="nav__item" @click="sideMenu('cabinet')"><a>Личный кабинет</a></li>
        <li class="nav__item"><router-link to="/tasks">Задания</router-link></li>
        <li class="nav__item"><router-link to="/messages">Сообщения</router-link></li>
      </ul>
    </nav>
    <form class="search" @submit.prevent="searchProducts">
      <span class="search__close" @click="closeSearch"></span>
      <button class="search__btn" type="submit"></button>
      <input :class="{ active: searchValue.length }" class="search__input" type="text" placeholder="поиск по товарам"
        v-model="searchValue" @keyup="searchProducts">
      <span class="search__clear" @click="clearSearch()"></span>
    </form>
    <div class="header-right">
      <div class="basket-menu-icon-wrap">
        <basket-menu-icon />
      </div>


      <router-link to="/history" v-if="balance" class="header-balance">
        <span class="header-balance__txt">Баланс</span>
        <div class="header-balance__quantity">{{ balance.data.balance }}</div>
      </router-link>

    </div>
    <div v-if="searchValue" class="search-container">
      <div class="search-result">
        <div class="container" v-if="products">
          <ul class="search-result-list" v-for="(product, idx) in products.data" :key="idx">
            <li v-if="product" class="search-result-list__item">
              <router-link v-on:click.native="clearSearch"
                :to="`/${product.is_custom ? 'catalog-custom' : 'catalog'}/${product.category_id}/${product.id}`">
                {{ product.name + (product.params.color ? ' ' + product.params.color : '') }}
              </router-link>
            </li>
            <li class="search-result-list__item" v-else>Нет товаров</li>
          </ul>
          <div v-if="products.data.length == 0" class="no-products">Товары не найдены</div>
        </div>
      </div>
      <span class="search__clear search__clear--results" @click="clearSearch()"></span>
    </div>
  </header>
</template>

<script>
import { EventBus } from '@/main.js';
import { mapState } from 'vuex'
import BasketMenuIcon from '@/components/BasketMenuIcon'
export default {
  data() {
    return {
      isOpen: false,
      searchActive: false,
      searchValue: "",
    }
  },

  computed: {
    ...mapState({
      products: state => state.search.products,
      balance: state => state.auth.balance,
      basket: state => state.basket.basket
    }),
  },

  watch: {
    searchValue() {
      this.searchValue = this.searchValue.replace(/[^\w,а-яА-Я-\s]/g, '').replace(/^\s/, '').replace(/\s{2,}/, ' ')
    }
  },

  methods: {
    sideMenu(menuClicked) {
      this.isOpen = true
      EventBus.$emit('menuClicked', { "menuClicked": menuClicked, "isOpen": this.isOpen });
    },

    closeSearch() {
      this.searchActive = false
      this.clearSearch();
      EventBus.$emit('closeSearch', { "searchActive": this.searchActive });
    },

    searchProducts() {
      if (this.searchValue)
        this.$store.dispatch('fetchSearchProducts', { searchValue: this.searchValue })
    },

    clearSearch() {
      this.searchValue = ""
    },

  },

  created() {
    EventBus.$on('showSearch', params => {
      this.searchActive = params.searchActive
    });

    this.$store.dispatch('getMeBalance')
    this.$store.dispatch('fetchBasket')
  },

  components: {
    BasketMenuIcon
  }
}
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 0 30px;
  background: $grey-bg;

  @include display-less(phablet) {
    padding: 0 20px;
  }
}

.nav-wrap {
  margin-left: 100px;

  @include display-less(hd) {
    margin-left: 40px;
  }

  @include display-less(big) {
    display: none;
  }
}

.nav {
  display: flex;

  &__item {
    margin-right: 40px;

    @include display-less(hd) {
      margin-right: 20px;
    }

    &:last-child {
      margin-right: 0px;
    }

    & a,
    div {
      font: 400 14px/16px $roboto;
      text-transform: uppercase;
      color: #d1d1d1;
      transition: color .2s;
      cursor: pointer;

      @include display-less(hd) {
        font: 400 12px/14px $roboto;
      }

      &.router-link-active {
        color: #fff;
      }

      &:hover {
        color: #fff;
      }
    }
  }
}

.search {
  margin-left: 40px;
  display: flex;
  align-items: center;
  position: relative;

  @include display-less(big) {
    display: none;
  }

  &__close {
    width: 20px;
    height: 20px;
    background-color: transparent;
    background-image: url('../assets/images/icons/close-search.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    margin-right: 15px;
    display: none;
  }

  &__btn {
    width: 20px;
    height: 20px;
    background-color: transparent;
    background-image: url('../assets/images/icons/icon-search.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    margin-right: 15px;
  }

  &__input {
    width: 305px;
    height: 30px;
    background: #1A1C1C;
    border: 1px solid #5A5A5A;
    border-radius: 4px;
    padding: 0 30px 0 15px;
    color: #fff;
    font: 400 12px/30px $roboto;

    //text-transform: uppercase;
    &::-webkit-input-placeholder {
      color: $grey-font;
    }

    &::-moz-placeholder {
      color: $grey-font;
    }

    &:-moz-placeholder {
      color: $grey-font;
    }

    &:-ms-input-placeholder {
      color: $grey-font;
    }

    &:focus::-webkit-input-placeholder {
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:focus::-moz-placeholder {
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:focus:-moz-placeholder {
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:focus:-ms-input-placeholder {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  &__clear {
    display: none;
    position: absolute;
    right: 20px;
    top: 10px;
    width: 10px;
    height: 10px;
    background-image: url('../assets/images/icons/clear.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;

    &--results {
      display: block;
      top: 15px;
      right: 25px;
      z-index: 20;
    }
  }
}

.search .search__input.active+.search__clear {
  display: block;
}

.basket-menu-icon-wrap {
  @include display-less(big) {
    display: none;
  }
}

.header-right {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.header-balance {
  display: flex;
  align-items: center;
  margin-left: 48px;

  &__txt {
    font: 400 14px $roboto;
    color: $grey-font;
    text-transform: uppercase;
    margin-right: 10px;
    color: #d1d1d1;
  }

  &__quantity {
    display: flex;
    justify-content: center;
    border: 2px solid #F47D32;
    box-sizing: border-box;
    border-radius: 4px;
    min-width: 40px;
    font: 400 14px/16px $roboto;
    color: #F47D32;
    padding: 2px 4px;
    font-size: 15px;
  }
}

.mSearchActive {
  @include display-less(big) {

    .header-logo,
    .header-balance {
      display: none;
    }

    .search {
      display: flex;
      margin-left: 0px;
      width: 100%;

      &__input {
        width: 100%;
      }

      &__close {
        display: block;
      }

      &__btn {
        display: none;
      }
    }
  }
}

.search-result {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding-top: 10px;
  height: 190px;
  background: #FFFEFA;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 4px 4px;
  z-index: 20;
  overflow-y: auto;
}

.search-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  height: 190px;
  background: #FFFEFA;
}

.search-result-list {
  &__item {
    height: 36px;
    border-bottom: 1px solid #DCDCDC;
    display: flex;
    align-items: center;

    a {
      font: 400 14px/1 $roboto;
      color: #1E1E1E;
      display: block;
      width: 100%;

      &:hover {
        color: #000;
      }
    }
  }
}

.no-products {
  font: 400 25px/1 $roboto;
  color: #000;
  margin-top: 30px;
}
</style>

