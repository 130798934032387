import Vue from 'vue'

export default {
  state: {
    history: null,
  },

  mutations: { 
    SET_HISTORY(state, payload) {
      state.history = payload
    }
  },

  actions: {
    fetchHistory({ commit}) {
      Vue.http.get( 
        `/api/history`
      )
      .then(response => { 
        commit('SET_HISTORY', response)
      })
      .catch(error => {
        console.log( error );
      });
    }
  } 
}