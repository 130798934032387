export class Category  {
  constructor () {
    this.id = null
    this.parent = null
    this.children = []
  }

  get parents () {
    const ids = [this.id]
    let parent = this.parent
    while (parent) {
      ids.push(parent.id)
      parent = parent.parent
    }

    return ids
  }

  get countParents () {
    return this.parents.length
  }

  static from (category) {
    return Object.assign(new this(), category);
  }

  static setCategories (categories) {
    return categories.map(category => {
      if (category.parentId) {
        category.parent = categories.find(parent => parent.id === category.parentId)
        category.parent.children.push(category)
      }
      return category
    }).filter(item => {
      return !item.parentId
    })
  }
}
