<template>
  <div class="bottom-menu-wrap">
    <div class="bottom-menu">
      <basket-menu-icon />
      <div :class="{active: menuActive}" class="hamburger" @click="toggleMenu"></div>
      <div :class="{active: searchActive}" class="bottom-menu__search" @click="showSearch"></div>
    </div>
    <div :class="{active: menuActive}" class="bottom-nav-wrap">
      <ul class="bottom-nav">
        <li class="bottom-nav__item" @click="bottomSubMenu('catalog')"><a>Каталог</a></li>
        <li class="bottom-nav__item"><router-link to="/instructions">Инструктаж</router-link> </li>
        <li class="bottom-nav__item" @click="bottomSubMenu('cabinet')"><a>Личный кабинет</a></li>
        <li class="bottom-nav__item"><router-link to="/tasks">Задания</router-link></li>
      </ul>
      <bottom-sub-menu />
    </div>
  </div>
</template>

<script>
  import BottomSubMenu from '@/components/BottomMenu/BottomSubMenu'
  import BasketMenuIcon from '@/components/BasketMenuIcon'
  import { EventBus } from '@/main.js';
  export default {
    data() {
      return {
        menuActive: false,
        searchActive: false
      }
    },

    methods: {
      bottomSubMenu(menuClicked) {
        EventBus.$emit('bottomMenuClicked', {"menuClicked": menuClicked, "bottomMenuActive": this.menuActive} );
      },

      toggleMenu() {
        this.menuActive = !this.menuActive
      },

      showSearch() {
        this.searchActive = !this.searchActive
        EventBus.$emit('showSearch', {"searchActive": this.searchActive});
      }
    },

    created() {
      EventBus.$on('closeSearch', params => {
        this.searchActive = params.searchActive
      });
    },

    components: {
      BottomSubMenu,
      BasketMenuIcon
    }
  }
</script>

<style lang="scss" scoped>
  .bottom-menu-wrap {
    display: none;
    @include display-less(big) {
      display: block
    }
  }

  .bottom-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #232525;
    height: 60px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 600!important;

    &__search {
      width: 20px;
      height: 20px;
      background-color: transparent;
      background-image: url('../../assets/images/icons/icon-search.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      margin-right: 15px;
      &.active {
         background-image: url('../../assets/images/icons/icon-search-active.svg');
      }
    }
  }

  .hamburger {
    width: 20px;
    height: 20px;
    display: block;
    margin: 0 75px;
    background-image: url('../../assets/images/icons/icon-menu-hamurger-active.svg');
    cursor: pointer;
    &.active {
      background-image: url('../../assets/images/icons/icon-menu-hamurger.svg');
    }
  }

  .bottom-nav {
    background: #232525;
    color: #000;
    display: block;
    padding: 30px 10px;
   
    &.active {
      bottom: 60px;
    }
    &__item {
      margin-bottom: 20px;
      &__last-child {
        margin-bottom: 0px;
      }
      a, span {
        display: block;
        cursor: pointer;
        color: #838383;
        text-align: center;
        font: 400 14px/16px $roboto;
        text-transform: uppercase;
      }
    }
  }

  .bottom-nav-wrap {
    position: fixed;
    height: 184px;
    left: 0;
    right: 0;
    bottom: -203px;
    transition: bottom .2s;
    z-index: 100!important;
    &.active {
      bottom: 60px;
    }
  }
</style>