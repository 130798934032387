<template>
  <div class="product-detailed-wrap">
    <div class="product-detailed-main">
      <div class="container">
        <div class="row ">
          <div class="catalog-nav">
            <side-nav-btn />
            <ul class="breadcrumbs">
              <template v-if="category">
                <li style="color: #fff" v-if="category.parentId != null"><router-link :to="{name: customCatalog ? 'products-custom' : 'products', params:{id: category.parentId}}">{{category.name}}</router-link></li>
                <li style="color: #fff" v-else><router-link :to="{name: customCatalog ? 'products-custom' : 'products', params:{id: category.id}}">{{category.name}}</router-link></li>
              </template>
              <li v-if="product" style="color: #fff"><span>{{ product.name }}</span></li>
            </ul>
          </div>
        </div>

        <div v-if="product" class="product-detailed">
          <span @click="back()" class="back"></span>
          <div class="product-detailed__top">
            <div v-if="product" class="product-detailed__img">
              <img :src="imageSrc" :alt="product.name">
            </div>
            <div class="product-detailed__right">
              <div class="product-detailed-right-top">
              <div class="flex-order-mobile">
                <div class="product-detailed__name">{{ product.name + (!customCatalog ? (product.params.color ? ' ' + product.params.color : '') : '') }}</div>
                <div class="product-detailed__specifications">{{ product.name }}</div>
              </div>
              <ul  class="product-detailed-images">
                <li v-for="(image, idx) in product.picture" :key="idx" class="product-detailed-images__item">
                  <img
                    @click="selectImage(image)" 
                    :src="image" 
                    :alt="product.name">
                </li>
              </ul>
            </div>
            <div class="product-detailed-right-bot">
                <div class="card-price">
                  {{ parseInt(product.priceWithDelivery) }}
                  <span class="card-price__ico"></span>
                </div>
                <add-to-basket :productId="productId" :custom="customCatalog"/>
              </div>
            </div>
          </div>
          <div class="product-detailed__bot">
            <h2 class="product-detailed-heading">Подробнее</h2>
            <p class="product-detailed-desc" v-html="product.description"></p>
          </div>
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
  import SideNavBtn from '@/components/SideNavBtn'
  import AddToBasket from '@/components/AddToBasket'
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        imageSrc: '',
        userId: '',
        productId: ''
      }
    },

    props: {
      customCatalog: Boolean
    },

    computed: {
      ...mapState({
        product: state => state.products.productById,
        category: state => state.products.categoryById,
        userData: state => state.profile.user,
      }),
    },

    methods: {
      selectImage(src) {
        this.imageSrc = src
      },

      back() {
        this.$router.go(-1)
      }
    },

    watch: {
      userData() {
        if(this.userData) {
          this.userId = this.userData.id
        }
      },

      product() {
        if(this.product) {
          this.productId = this.product.id
          this.imageSrc = this.product.picture ? this.product.picture[0] : ''
        }
      },
    },

    created() {
      if (!this.customCatalog) {
        this.$store.dispatch('fetchProductById', this.$route.params.product )
        this.$store.dispatch('fetchCategoryById', this.$route.params.id)
      } else {
        this.$store.dispatch('fetchCustomProductById', this.$route.params.product )
        this.$store.dispatch('fetchCustomCategoryById', this.$route.params.id)
      }
      //this.$store.dispatch('fetchProductsByCategory', this.$route.params.id)
      this.$store.dispatch('fetchUser')
    },

    components: {
      SideNavBtn,
      AddToBasket
    }
  }
</script>

<style lang="scss" scoped>
  .product-detailed-main {
    position: relative;
    background: #1A1C1C;
    z-index: 1;
    padding-bottom: 100px;
    min-height: calc(100vh - 184px);
  }

  .product-detailed {
    background: #FFFEFA; 
    padding: 30px;
    margin-top: 20px;
    position: relative;

    &__top {
      display: flex;
      border-bottom: 1px solid #C4C4C4;
      padding-bottom: 40px;
      @include display-less(tablet) {
        flex-direction: column;
        align-items: center;
        padding-bottom: 0px;
      }
    }

    &__bot {
      padding-top: 45px;
      padding-bottom: 10px;
      @include display-less(tablet) {
        padding-top: 35px;
      }
    }

    &__img {
      height: 347px;
      cursor: pointer;
      @include display-less(tablet) {
        max-height: 310px;
      }
      @include display-less(smart) {
        max-height: 200px;
        width: 167px;
      }

      img {
        display: block;
        max-height: 347px;
        @include display-less(tablet) {
          max-height: 310px;
        }
        @include display-less(smart) {
          max-height: 200px;
          width: 167px;
        }
      }
    }

    &__right {
      margin: 20px 20px 0 30px;
      display: flex;
      flex-direction: column;
      @include display-less(smart) {
        margin-top: 0px;
      }
    }

    &__name {
      font: 400 18px/21px $roboto;
      color: #1E1E1E;
      @include display-less(tablet) {
        font: 400 14px/16px $roboto;
        margin-top: 30px;
      }
    }

    &__specifications {
      margin-top: 10px;
      font: 400 14px/16px $roboto;
      color: #8B8B8B;
    }
  }

  .product-detailed-right-top {
    display: flex;
    flex-direction: column;
  }

  .flex-order-mobile {
    @include display-less(tablet) {
      order: 2
    }
  }

  .product-detailed-images { 
    display: flex;
    align-items: center;
    margin-top: 50px;
    @include display-less(tablet) {
      margin-top: 10px;
      justify-content: center;
    }

    &__item {
      max-height: 100%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
      height: 100px;
      border:1px solid #232525;
      border-radius: 4px;
      cursor: pointer;
      @include display-less(smart) {
        width: 70px;
        height: 70px;
        margin-right: 15px;
      }
      &:last-child {
        margin: 0;
      }

      img {
        display: block;
        max-height: 100%;
        border-radius: 4px;
      }
    }
  }

  .product-detailed-right-bot {
    display: flex;
    margin-top: auto;
    margin-bottom: 20px;
    @include display-less(tablet) {
      margin-top: 30px;
    }

    .basket-item {
      margin-left: auto;
    }
  }

  .card-price {
    font: 700 32px/37px $roboto;
    color: #FF9C43;
    display: flex;
    margin-top: 5px;
    @include display-less(tablet) {
      position: relative;
      top: 4px;
    } 

    &__ico {
      width: 20px;
      height: 18px;
      display: block;
      background-image: url('../assets/images/icons/coins.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      align-self: flex-end;
      margin: 0 0 7px 10px;
    }
  }

  .product-detailed-heading {
    font: 400 18px/21px $roboto;
    color: #1E1E1E;
    margin-bottom: 20px;
  }

  .product-detailed-desc {
    font: 300 14px/16px $roboto;
    color: #000000;

  }

  .back {
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    right: 15px;
    top: 15px;
    background-image: url('../assets/images/icons/close-menu.svg');
    cursor: pointer;
  }

  .catalog-nav {
    margin-left: 10px;
    margin-top: 20px;
    display: flex;
  }

  .breadcrumbs {
    display: flex;
    li {
      a, span {
        display: block;
        height: 30px;
        background: #232525;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 50px;
        color: #fff;
        font: 300 16px/30px $roboto;
        padding: 0 10px;
        margin-left: 10px;
        text-overflow: ellipsis;
        overflow: hidden; 
        max-width: 150px; 
        white-space: nowrap;
      }
    }
  }
</style>