<template>
  <div class="admin-messages-top">
    <div class="messages-result-wrap">
      <div class="messages-result-head">
        <div class="col messages-result-head__date">Дата</div>
        <div class="col messages-result-head__status">Статус</div>
        <div class="col messages-result-head__to">Кому</div>
        <div class="col messages-result-head__title">Заголовок</div>
        <div class="col messages-result-head__short">Краткое описание</div>
      </div>

      <div class="messages-result-list-wrap" @scroll="handleScroll">
        <div class="list-wrap">
          <div
            class="messages-result"
            v-for="(msg, idx) in messages"
            :key="idx"
            :class="{ active: msg.id == selectedId }"
            @click="
              selectMessage(msg);
              (selectedId = msg.id), switchForm(false);
            "
          >
            <div class="col messages-result__date">
              {{ dateFormatter(msg.createdAt) }}
            </div>
            <div class="col messages-result__status">
              {{ statusFormatter(msg.status) }}
            </div>
            <div class="col messages-result__to">
              {{ groupsFormatter(msg.groups) }}
            </div>
            <div class="col messages-result__title">
              {{ tuncLongString(msg.title, 25) || '-'}}
            </div>
            <div class="col messages-result__short">
              {{ tuncLongString(msg.shortDescription, 25) || '-' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { truncateString } from "@/utils";

export default {
  props: {
    messages: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedId: null,
    };
  },
  methods: {
    dateFormatter(date) {
      return moment(date).format("DD.MM.YYYY");
    },

    tuncLongString(str, lenght) {
      return truncateString(str, lenght);
    },

    groupsFormatter(groups) {
      const groupsToRussianMapping = {
        executor: "Исполнителю",
        manager: "Руководителю",
        masterInspector: "Мастеру-приемщику",
      };

      const formattedGroups = groups
        ? groups.map((group) => groupsToRussianMapping[group]).join(", ")
        : "-";

      return formattedGroups;
    },

    statusFormatter(status) {
      const statusToRussianMapping = {
        readed: "Прочитано",
        sended: "Отправлено",
        draft: "Черновик",
      };

      return statusToRussianMapping[status] ?? "-";
    },

    executorFormatter(groups) {
      return groups;
    },

    selectMessage(message) {
      this.$emit("select-message", message);
    },
    switchForm(value) {
      this.$emit("toggle-form", value);
    },
    handleScroll(event) {
      const bottomOfList = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
      if (bottomOfList && !this.loading) {
        this.$emit('load-more');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.messages-result-list-wrap {
  max-height: 280px;
  overflow-y: auto;
}

.messages-result-head {
  height: 30px;
  display: flex;
  border-bottom: 1px solid $gray-mid;

  .col {
    font: 400 14px/8px $roboto;
    color: $gray-dark;
    opacity: 0.6;
  }

  &__date {
    width: 10%;
  }

  &__name {
    width: 40%;
  }

  &__status {
    width: 10%;
  }

  &__to {
    width: 24%;
  }

  &__title {
    width: 17%;
  }

  &__short {
    width: 40%;
    left: 33px;
  }
}

.messages-result {
  min-height: 69px;
  display: flex;
  border-bottom: 1px solid $gray-light;
  cursor: pointer;

  &.active {
    background: $gray-lightest;
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 10px;
    }
  }

  &:hover {
    background: lighten($gray-light, 4%);
  }

  &__name {
    width: 40%;
  }

  &__status {
    width: 11%;
  }

  &__author {
    width: 30%;
  }

  &__to {
    width: 25%;
  }

  &__title {
    width: 20%;
  }

  &__short {
    width: 40%;
  }

  &__date {
    width: 10%;
  }


  .col {
    font: 400 14px/25px $roboto;
    color: $dark-font;
  }
}

.admin-messages {
  position: relative;

  textarea {
    padding: 10px;
    margin-top: 28px;
    margin-bottom: 30px;
    width: 100%;
    min-height: 200px;
    border: 1px solid $gray-mid-dark;
    color: $gray-mid-dark;
    font: 400 16px/20px $roboto;
    color: $dark-font;

    &:focus {
      border: 1px solid $blue;
    }
  }

  .input-wrap {
    input {
      width: 100%;
      height: 30px;
      border: none;
      border-bottom: 1px solid $gray-semi-dark;
      color: $gray-mid-dark;
      font: 400 16px/30px $roboto;
      padding: 0 5px;
      color: $dark-font;

      &:placeholder {
        color: $gray-mid-dark;
      }

      &:focus {
        border-bottom: 1px solid $blue;
      }
    }

    label {
      position: relative;
      display: inline-block;
      width: 100%;
    }

    span {
      padding: 5px;
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0;
      transition: 0.2s;
      transition-timing-function: ease;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
      opacity: 0.5;
      color: $dark-font;
      font: 400 16px $roboto;
    }

    input:focus + span,
    input:not(:placeholder-shown) + span {
      opacity: 1;
      transform: translateY(-100%) translateX(-10px);
      font-size: 13px;
    }

    input:focus + span,
    input:not(:-ms-input-placeholder) + span {
      opacity: 1;
      transform: translateY(-100%) translateX(-10px);
      font-size: 13px;
    }

    textarea:focus + span,
    textarea:not(:placeholder-shown) + span {
      opacity: 1;
      transform: translateY(-100%) translateX(-10px);
      font-size: 13px;
    }

    textarea:focus + span,
    textarea:not(:-ms-input-placeholder) + span {
      opacity: 1;
      transform: translateY(-100%) translateX(-10px);
      font-size: 13px;
    }
  }
}

.messages-list-btns {
  display: flex;
  margin-top: 20px;

  .add {
    margin-right: auto;
  }

  .remove,
  .save {
    margin-left: auto;
  }

  .messages-list-btn {
    display: block;
    height: 40px;
    padding: 0 15px;
    color: $white;
    font: 400 14px/8px $roboto, sans-serif;
    background: $admin-blue;
    border: none;
    cursor: pointer;

    &:hover {
      background: lighten($admin-blue, 10%);
    }
  }
}

.messages-form-list-btns {
  display: flex;
  gap: 10px;
  margin-top: 20px;

  .add {
    margin-right: auto;
  }

  .remove,
  .save {
    margin-left: auto;
  }

  .messages-form-list-btn {
    display: block;
    height: 40px;
    padding: 0 15px;
    color: $white;
    font: 400 14px/8px $roboto, sans-serif;
    background: $admin-blue;
    border: none;
    cursor: pointer;

    &:hover {
      background: lighten($admin-blue, 10%);
    }
  }

  .messages-form-list-dbtn {
    display: block;
    height: 40px;
    padding: 0 15px;
    color: $white;
    font: 400 14px/8px $roboto, sans-serif;
    background: $admin-red;
    border: none;
    cursor: pointer;

    &:hover {
      background: lighten($admin-red, 10%);
    }
  }
}
</style>
