import Vue from 'vue'


/**
 * Стор для модальных окон
 */

export default {
    state: {
        modals: {}
    },

    mutations: {
        OPEN_MODAL(state, modalName) {
            Vue.set(state.modals, modalName, true);
        },
        CLOSE_MODAL(state, modalName) {
            Vue.set(state.modals, modalName, false);
        },
        TOGGLE_MODAL(state, modalName) {
            const currentState = state.modals[modalName];
            Vue.set(state.modals, modalName, !currentState);
        }
    },
    actions: {
        openModal({ commit }, modalName) {
            commit('OPEN_MODAL', modalName);
        },
        closeModal({ commit }, modalName) {
            commit('CLOSE_MODAL', modalName);
        },
        toggleModal({ commit }, modalName) {
            commit('TOGGLE_MODAL', modalName);
        }
    },
    getters: {
        isModalOpen: (state) => (modalName) => {
            return !!state.modals[modalName];
        }
    }
};
