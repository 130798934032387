<template>
<div class="ImageViewerContainer">
  <img :src="imageData" class="ImageViewer">
</div>
</template>
<script>

import axios from "axios";

export default {
  data() {
    return {
      imageData: null,
    }
  },
  mounted() {
    let urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
      if(urlParams.image){
        let imageParams = urlParams.image.split(":");
        let folderId = imageParams[0];
        let imageId = imageParams[1];

        if(folderId && imageId){

          let url = process.env.VUE_APP_baseURL + "/api/users/"+folderId+"/images/"+imageId;

          axios.request({
            method: "GET",
            url: url,
            headers: {
              "Authorization": "Bearer "+window.localStorage.jwt
            }
          }).then((response) => {
            console.log(response);
            this.imageData = response.data;
          })
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.ImageViewerContainer{
  min-width: 100% !important;
  min-height: 100%!important;
  .ImageViewer{
    position: absolute;
    margin-left: 5%;
    marign-right: auto;
    max-height: 90%;
    max-width: 90%;
  }
}
</style>
