<template>
  <div @click="sideMenu('catalog')" class="catalog-nav-btn"></div>
</template>

<script>
  import { EventBus } from '@/main.js';
  export default {
    methods: {
      data() {
        return {
          isOpen: false,
        }
      },

      sideMenu(menuClicked) {
        this.isOpen = true
        EventBus.$emit('menuClicked', {"menuClicked": menuClicked, "isOpen": this.isOpen} );
      },
    }
  }
</script>

<style lang="scss" scoped>
  .catalog-nav-btn {
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-image: url('../assets/images/icons/button-catalog-menu.svg');
    background-position: center;
    background-repeat: no-repeat;
  }
</style>