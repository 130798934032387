<template>
  <div id="app">
    <component :is="layout">
      <router-view :key="$route.fullPath"></router-view>
    </component>
  </div>
</template>

<script>
import DefaultLayout from "@/shared/layouts/DefaultLayout";
import ClearLayout from "@/shared/layouts/ClearLayout";
export default {
  name: 'App',
  components: {DefaultLayout, ClearLayout},
  computed: {
      layout(){
          if( !this.$route.matched.length )
              return 'ClearLayout';

          let match = this.$route.matched[0];

          if( typeof match.props === 'object' && match.props.default  && match.props.default.layout){
              return match.props.default.layout;
          }

          return 'ClearLayout';
      }
  }
}
</script>
<style lang="scss">
</style>
