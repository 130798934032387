<template>
  <div class="admin-login">
    <div class="admin-login-error" v-if="loginError">
      Неверный логин или пароль
    </div>
    <div class="admin-login-form">
      <input type="text" class="admin-login-form__input" placeholder="Логин" v-model="login" @keyup.enter="loginAdmin">
      <input type="password" class="admin-login-form__input" placeholder="Пароль" v-model="password" @keyup.enter="loginAdmin">
      <button class="admin-login-form__submit" @click="loginAdmin">Войти</button>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        login: '',
        password: ''
      }
    },
    computed: {
      ...mapState({
        loginError: state => state.authAdmin.loginError,
      }),
    },

    methods: {
      loginAdmin() {
        this.$store.dispatch('loginAdmin', {login: this.login, password: this.password})
      }
    }
  }
</script>

<style lang="scss" scoped>
  .admin-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    &-error{
      font: 300 18px/21px Roboto,sans-serif;
      color: #ff5656;
      margin-bottom: 15px;
    }
  }
  .admin-login-form {
    width: 300px;
    &__input {
      width: 100%;
      height: 30px;
      border: none;
      border-bottom: 1px solid #9e9e9e;
      color: #9e9e9e;
      font: 400 16px/30px $roboto;
      padding: 0 5px;
      color: $dark-font;
      margin-bottom: 30px;

      &:placeholder {
        color: #9e9e9e;
      }

      &:focus {
        border-bottom: 1px solid blue;
      }
    }

    &__submit {
      display: block;
      height: 40px;
      padding: 0 30px;
      color: #fff;
      font: 400 16px/40px $roboto;
      background: $admin-blue;
      border: none;
      cursor: pointer;
      margin: 0 auto;
      &:hover {
        background: darken($admin-blue, 10%)
      }
    }
  }
</style>