<template>
  <div class="orders">

    <order-popup v-if="popupIsActive" @popupIsActive="popupIsActive = false" :orderUpdatePopup="popupIsActive"
      :orderId="orderId" :updateOrder="updateOrder" />



    <div class="orders-top">
      <div class="orders-top-row">
        <div class="datepicker">
          <div class="datepicker__info">c:</div>
          <div class="datepicker__calendar">
            <datepicker :language="ru" :format="customFormatter" :disabled-dates="disabledDatesStart"
              input-class="dateInput" wrapper-class="dateInputWrap" v-model="startDate" @selected="dateChange" />
          </div>
        </div>

        <div class="datepicker">
          <div class="datepicker__info">по:</div>
          <div class="datepicker__calendar">
            <datepicker :language="ru" :format="customFormatter" :disabled-dates="disabledDatesEnd"
              input-class="dateInput" wrapper-class="dateInputWrap" v-model="endDate" @selected="dateChange">
            </datepicker>
          </div>
        </div>

        <button @click="downloadRegistry" class="orders-registry">
          Выгрузить реестр для поставщика
        </button>

        <button @click="downloadAccounting" class="orders-registry">
          Выгрузить реестр для бухгалтерии
        </button>
      </div>

      <h1 class="orders-heading">Заказы</h1>
      <div class="orders-list-wrap" @scroll="onScroll">
        <div v-if="messageIsActive" class="orders-list-wrap__message"
          :class="{ ' wrong': messageOrder === 'Данные не сохранились' }">
          {{ messageOrder }}
        </div>
        <div class="orders-list-head">
          <div class="col" v-for="(field, id) in orderField" :key="id" :style="field.style">
            {{ field.name }}
          </div>
        </div>

        <div class="orders-result-wrap">
          <div class="list-wrap" v-if="orders">
            <order v-for="(order, idx) in filterOrders" :order="order" :status="status" :options="options" :key="idx"
              :class="{ active: order.id == selected }" @savedString="messageShow($event)" @getOrder="
                getOrderId($event.id, $event.user.id);
              currentOrder = $event;
              selected = $event.id;
              " />
          </div>
        </div>
      </div>
      <div class="orders-bot" v-if="userData">
        <div class="orders-head">
          <h2 class="orders-head__title">Заказ {{ order.id }}</h2>

          <div class="orders-head__btns">
            <button class="button button--save" @click="accordingAction">
              Сохранить заказ
            </button>

            <button @click="downloadPdf" class="button">Выгрузить акт</button>
          </div>
        </div>

        <div class="status">
          <div class="status-select">
            <v-select class="admin-select" :options="options" label="name" v-model="status">
              <template v-slot:selection="option">
                <img class="option__desc" :src="option.image" />
                {{ option.name }}
              </template>

              <template v-slot:option="option">
                <img class="option__desc" :src="option.image" />
                {{ option.name }}
              </template>
              <template v-slot:selected-option="option">
                <img class="option__desc" :src="option.image" />
                {{ option.name }}
              </template>
              <template v-slot:no-options>
                <span>Выберете из списка</span>
              </template>
            </v-select>
          </div>
        </div>

        <user-order :userData="userData" v-if="userData" />
        <div class="order-result-wrap">
          <div class="order-list-head">
            <div class="col order-list-head__prod">Товар</div>
            <div class="col order-list-head__price">Цена</div>
            <div class="col order-list-head__num">Количество</div>
            <div class="col order-list-head__resprice">Стоимость</div>
          </div>

          <div class="order-result-wrap" v-if="order && order.purchases">
            <div class="order-list" v-for="item in order.purchases.goods ? order.purchases.goods : []" :key="item.id">
              <div class="col order-list__prod">{{ item.name }}</div>
              <div class="col order-list__price">{{ item.price }}</div>
              <div class="col order-list__num">{{ item.count }}</div>
              <div class="col order-list__resprice">
                {{ item.count * item.price }}
              </div>
            </div>
            <div class="order-list" v-for="item in order.purchases && order.purchases.customGoods
              ? order.purchases.customGoods
              : []" :key="item.id">
              <div class="col order-list__prod">{{ item.name }}</div>
              <div class="col order-list__price">{{ item.price }}</div>
              <div class="col order-list__num">{{ item.count }}</div>
              <div class="col order-list__resprice">
                {{ item.count * item.price }}
              </div>
            </div>
          </div>
          <div class="order-total" v-if="order">
            <div class="order-total__title">ИТОГО</div>
            <div class="order-total__val">{{ order.purchases.totalPrice }}</div>
          </div>
          <div class="order-comment" v-if="order">
            <div class="order-total__title">Комментарий к заказу</div>
            <div class="order-total__comment">{{ order.comment }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import Order from "./components/Order.vue";
import OrderPopup from "./components/OrderPopup.vue";
import UserOrder from "./components/UserOrder.vue";
import { ru } from "vuejs-datepicker/dist/locale";
import { mapState, mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      status: "",
      ru: ru,
      selected: undefined,
      orderId: null,
      userId: null,
      purchases: null,
      isDateChanged: false,
      startDate: moment()
        .subtract(1, "months")
        .toDate(),
      endDate: moment().toDate(),
      ordersList: null,
      offset: 0,
      limit: 10,
      disabledDatesStart: {
        from: moment().toDate(),
      },
      disabledDatesEnd: {
        to: moment()
          .subtract(1, "months")
          .toDate(),
      },
      orderUpdatePopup: false,
      currentOrder: {},
      orderField: {
        date: {
          name: "Дата",
          style: "width: 150px;",
        },
        id: {
          name: "Номер заказа",
          style: "width: 120px;",
        },
        get: {
          name: "Поставщик",
          style: "width: 120px; text-align: center;",
        },
        user: {
          name: "Получатель",
          style: "width: 250px; text-align: center;",
        },
        reportId: {
          name: "Номер списания",
          style: "width: 140px; text-align: center;",
        },
        reportDate: {
          name: "Дата списания",
          style: "width: 130px;",
        },
        numberTTN: {
          name: "Номер ТТН",
          style: "width: 140px; text-align: center;",
        },
        dateTTN: {
          name: "Дата ТТН",
          style: "width: 120px;",
        },
        reportChecked: {
          name: "Вручен",
          style: "width: 120px;",
        },
        status: {
          name: "Статус",
          style: "width: 140px; text-align: center;",
        },
        goods: {
          name: "Товар",
          style: "width: 150px; text-align: center;",
        },
        price: {
          name: "Цена, руб.",
          style: "width: 120px; text-align: center;",
        },
        paymentMethod: {
          name: "Нал\\Безнал",
          style: "width: 120px; text-align: center;",
        },
        phone: {
          name: "Номер телефона",
          style: "width: 170px; text-align: center;",
        },
      },
      popupIsActive: false,
      messageOrder: "",
      messageIsActive: false,
      options: [
        {
          name: "На сборке",
          status: 20,
          image: require("../../../assets/images/icons/rounds/orange.svg"),
        },
        {
          name: "В пути",
          status: 30,
          image: require("../../../assets/images/icons/rounds/yellow.svg"),
        },
        {
          name: "Доставлен",
          status: 40,
          image: require("../../../assets/images/icons/rounds/green.svg"),
        },
        {
          name: "Отменен",
          status: 50,
          image: require("../../../assets/images/icons/rounds/red.svg"),
        },
      ],
    };
  },

  computed: {
    ...mapState({
      orders: (state) => state.orders.orders,
      order: (state) => state.orders.order,
      act: (state) => state.orders.act,
      userData: (state) => state.users.user,
      registry: (state) => state.orders.registry,
      accounting: (state) => state.orders.accounting,
    }),

    ...mapGetters(["getOrders"]),

    filterOrders() {
      let items = this.ordersList;

      if (this.isDateChanged === false) {
        return items;
      } else {
        this.getOrdes();
        return this.orderList;
      }
    },
  },

  methods: {
    messageShow(message) {
      this.messageOrder = "Данные сохранены";
      if (!message) {
        this.messageOrder = "Данные не сохранились";
      }
      this.messageIsActive = true;
      setTimeout(() => {
        this.messageIsActive = false;
      }, 800);
    },

    convertStatus(status) {
      switch (status) {
        case 10:
          return "Выбран";
        case 20:
          return "На сборке";
        case 30:
          return "В пути";
        case 40:
          return "Доставлен";
        case 50:
          return "Отменен";
      }
    },

    customFormatter(date) {
      return moment(date).format("DD.MM.YYYY");
    },

    getOrdes() {
      this.isDateChanged = false;
      let payload = { offset: this.offset, limit: this.limit };
      if (this.startDate && this.endDate) {
        payload.startDate = this.startDate.toISOString().slice(0, 10);
        payload.completeDate = this.endDate.toISOString().slice(0, 10);
      }
      this.$store.dispatch("getOrders", payload);
    },

    getOrderId(orderId, userId) {
      this.orderList = [];
      this.orderId = orderId;
      this.userId = userId;
      this.$store.dispatch("getOrder", orderId);
      this.$store.dispatch("getOrderAct", orderId);
      this.$store.dispatch("getUserById", this.userId);
    },

    downloadPdf() {
      this.$store.dispatch("getOrderAct", this.orderId).then(() => {
        setTimeout(() => {
          this.createLink(
            this.act,
            "application/pdf",
            this.userData.full_name + "_order_" + this.orderId + ".pdf"
          );
        }, 200);
      });
    },

    downloadAccounting() {
      //get file in watcher accounting
      this.$store.dispatch("getAccountingAct", {
        from: moment(this.startDate, "DD.MM.YYYY HH:mm").format("x"),
        to: moment(this.endDate, "DD.MM.YYYY HH:mm").format("x"),
      });
    },

    downloadRegistry() {
      //get file in watcher registry
      let start = moment(this.startDate).format("DD.MM.YYYY") + " 00:00";
      let end = moment(this.endDate).format("DD.MM.YYYY") + " 23:59";

      this.$store.dispatch("getRegistry", {
        from: moment(start, "DD.MM.YYYY HH:mm").format("x"),
        to: moment(end, "DD.MM.YYYY HH:mm").format("x"),
      });
    },

    createLink(blob, type, name) {
      const fileURL = URL.createObjectURL(new Blob([blob], { type: type }));
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", name);
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    },

    dateChange() {
      this.isDateChanged = true;
    },

    toTimestamp(strDate) {
      let formattedDate = Date.parse(strDate);
      return formattedDate / 100000;
    },

    async updateOrder() {
      this.popupIsActive = false;
      this.orderUpdatePopup = false;
      this.$store
        .dispatch("changeStatus", {
          orderId: this.orderId,
          price: this.currentOrder.price,
          status: this.status.status,
          onSuccess: () => {

            setTimeout(() => {
              this.getOrdes();
            }, 200);
            this.$toast("Статус заказа обновлен", {
              timeout: 2000
            })
          },
          onError: () => {
            this.$toast.error("Статус заказа не обновлен", {
              timeout: 2000
            })
          }
        })
    },

    accordingAction() {
      if (this.status.status === 40 || this.status.status === 50) {
        this.popupIsActive = true;
        this.orderUpdatePopup = true;
      } else {
        this.updateOrder();
      }
    },

    onScroll(event) {
      let wrapper = event.target,
        list = wrapper.firstElementChild;

      let scrollTop = wrapper.scrollTop,
        wrapperHeight = wrapper.offsetHeight,
        listHeight = list.offsetHeight;

      let diffHeight = listHeight - wrapperHeight;

      if (scrollTop > diffHeight - 30) {
        this.offset += 10;

        this.getOrdes();
      }
    },
  },

  watch: {
    orders() {
      if (this.orders != null) {
        this.ordersList = this.orders;
      }
    },

    order() {
      if (this.order != null) {
        this.status = {
          name: this.convertStatus(this.order.status),
          status: this.order.status,
          image: this.options.find((el) => el.status === this.order.status)
            .image,
        };
        this.$store.dispatch("changeStatus", {
          orderId: this.orderId,
          status: this.order.status,
          onSuccess: () => {
            this.$toast("Статус заказа обновлен", {
              timeout: 2000
            })
          },
          onError: () => {
            this.$toast.error("Статус заказа не может быть обновлен", {
              timeout: 2000
            })
          }
        });
      }
    },

    startDate() {
      this.disabledDatesEnd.to = this.startDate;
    },

    endDate() {
      this.disabledDatesStart.from = this.endDate;
    },
    // получение файла выгрузки бухгалтерии
    accounting: {
      handler(val) {
        if (val) {
          this.createLink(
            val,
            "application/vnd.ms-excel",
            `Реестр для бухгалтерии c ${this.customFormatter(
              this.startDate
            )} по ${this.customFormatter(this.endDate)}.xlsx`
          );
        }
      },
      deep: true,
    },
    // получение файла выгрузки поставщика
    registry: {
      handler(val) {
        if (val) {
          this.createLink(
            val,
            "application/vnd.ms-excel",
            `Реестр поставщика c ${this.customFormatter(
              this.startDate
            )} по ${this.customFormatter(this.endDate)}.xlsx`
          );
        }
      },
      deep: true,
    },
  },

  async created() {
    await this.getOrdes();
    this.$store.dispatch("clearUser");
    window.addEventListener("scroll", this.onScroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },

  components: {
    Datepicker,
    Order,
    OrderPopup,
    UserOrder,
  },
};
</script>

<style lang="scss" scoped>
.option__desc {
  display: inline-flex;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 10px;

  &_selected {
    display: inline-flex;
    align-items: center;
  }
}

.orders-list-wrap {
  overflow-x: scroll;
  width: fit-content;

  &__message {
    position: absolute;
    left: 45%;
    margin-top: -25px;
    color: $green;
    font-size: larger;

    &.wrong {
      color: $admin-red-darker;
    }
  }

  .col {
    flex-shrink: 0;
  }
}

.orders {
  padding-top: 40px;
}

.orders-top-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 32px;
}

.orders-heading {
  margin-top: 40px;
  margin-bottom: 10px;
  font: 700 32px/1.5 $roboto;
  color: $dark-font;
}

.orders-registry {
  display: block;
  background: $admin-blue;
  font: 400 16px/40px $roboto;
  color: $white;
  padding: 0 15px;
  border: none;
  cursor: pointer;

  &:hover {
    background: darken($admin-blue, 10%);
  }
}

.orders-list-head {
  max-height: 65px;
  display: flex;
  border-bottom: 1px solid $gray-blue;
  width: fit-content;

  .col {
    width: 20%;
    font: 400 14px/23px $roboto;
    color: $gray-mid-dark;

    &--15 {
      width: 15%;
    }

    &--25 {
      width: 25%;
    }

    &--50 {
      width: 25%;
    }
  }
}

.orders-result-wrap {
  max-height: 400px;
}

.orders-list {
  min-height: 40px;
  display: flex;
  border-bottom: 1px solid $gray-light;
  cursor: pointer;
  width: fit-content;

  &:hover {
    background-color: lighten($gray-light, 4%);
  }

  .col {
    width: 20%;
    font: 400 14px/40px $roboto;
    color: $dark-font;

    &--5 {
      width: 5%;
    }

    &--10 {
      width: 10%;
    }

    &--15 {
      width: 15%;
    }

    &--25 {
      width: 25%;
    }

    &--50 {
      width: 50%;
    }
  }
}

.orders-list.active {
  background-color: $gray-light;
  position: relative;
  width: fit-content;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10px;
    background-color: $admin-blue;
  }
}

.orders-head {
  display: flex;
  flex-direction: row;
  gap: 100px;
  margin-bottom: 20px;

  &__title {
    font: 400 24px/32px $roboto;
  }

  &__btns {
    display: flex;
    gap: 10px;
  }
}

.orders-bot {
  margin-top: 40px;
  padding-bottom: 100px;
}

.status-select {
  width: 200px;

  &__label {
    font: 400 13px/1 "Roboto";
    color: $gray-mid-dark;
  }
}

.status {
  display: flex;
  position: relative;
  width: 31%;
  justify-content: space-between;

  .message {
    position: absolute;
    width: 200px;
    color: $toxic-green;
    left: calc(100% + 10px);
  }
}

.button {
  background-color: $admin-blue;
  font: 400 16px/40px $roboto;
  color: $white;
  padding: 0 15px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: darken($admin-blue, 10%);
  }

  &--save {
    margin-top: 0;
  }
}

.order-list-head {
  height: 30px;
  display: flex;
  border-bottom: 1px solid $dark-font;
  align-items: center;

  .col {
    font: 400 14px/30px $roboto;
    color: $gray-mid-dark;
  }

  &__prod {
    width: 40%;
  }

  &__price {
    width: 20%;
  }

  &__num {
    width: 20%;
  }

  &__resprice {
    width: 20%;
  }
}

.order-result-wrap {
  width: 790px;
}

.order-list {
  // height: 40px;
  display: flex;
  border-bottom: 1px solid $gray-light;
  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: lighten($gray-light, 4%);
    width: fit-content;
  }

  &__prod {
    width: 40%;
  }

  &__price {
    width: 20%;
  }

  &__num {
    width: 20%;
  }

  &__resprice {
    width: 20%;
  }

  .col {
    font: 400 16px/1 $roboto;
    color: $dark-font;
  }
}

.order-list.active {
  background-color: $gray-light;
  position: relative;
  width: fit-content;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10px;
    background-color: $admin-blue;
  }
}

.order-total {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid $dark-font;

  &__title {
    font: 600 14px/40px $roboto;
    color: $dark-font;
    margin-right: auto;
    padding: 0 15px;
  }

  &__val {
    font: 700 16px/40px $roboto;
    color: $dark-font;
    margin-left: auto;
    width: 20%;
    text-align: left;
    padding-left: 30px;
    padding: 0 15px;
  }

  &__comment {
    font: 400 16px/1 $roboto;
    width: 100%;
    text-align: left;
    padding-left: 30px;
    padding: 0 15px;
  }
}

.datepicker {
  display: flex;
  align-items: center;

  &__info {
    font: 400 16px $roboto;
    color: $dark-font;
    margin-right: 10px;
  }

  &__calendar {
    border-bottom: 1px solid $dark-font;
    width: 150px;
  }
}
</style>
