/**
 * @description Обрезает строку до определенного количества символов
 * @param text - строка
 * @param length - длина строки
 */
export const truncateString = (
  text,
  length,
) =>
   text !== null && text.length > length
    ? `${text.substring(0, length)}...`
    : text;
