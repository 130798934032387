import { render, staticRenderFns } from "./AdminTasks.vue?vue&type=template&id=76254832&scoped=true"
import script from "./AdminTasks.vue?vue&type=script&lang=js"
export * from "./AdminTasks.vue?vue&type=script&lang=js"
import style0 from "./AdminTasks.vue?vue&type=style&index=0&id=76254832&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76254832",
  null
  
)

export default component.exports