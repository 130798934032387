<template>
  <form class="admin-messages-form" @submit.prevent="submitForm">
    <div class="admin-messages-head">
      <h2 class="admin-messages-heading-form">Новое сообщение</h2>
      <div class="messages-form-list-btns">
        <button @click="saveAsDraft" class="messages-form-list-btn add">
          <h3>Сохранить черновик</h3>
        </button>
        <button type="submit" class="messages-form-list-btn add">
          <h3>Отправить сообщение</h3>
        </button>
        <button disabled class="messages-form-list-dbtn add">
          <h3>Удалить</h3>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="message-wrap">
          <span class="message-wrap-title">Кому</span>
          <v-select class="message-wrap-select" multiple v-model="groups" label="label" :options="avalableGroups" />
          <span v-if="errors.groups" class="error-message">{{
            errors.groups
          }}</span>
        </div>

        <div class="message-wrap">
          <span class="message-wrap-title">Заголовок</span>
          <p class="input-container">
            <input v-model="title" class="message-wrap-input" type="text" maxlength="45" />
            <span class="char-counter">{{ title.length }}/45</span>
          </p>
          <span v-if="errors.title" class="error-message">{{
            errors.title
          }}</span>
        </div>

        <div class="message-wrap">
          <span class="message-wrap-title">Краткое описание</span>
          <p class="input-container">
            <input v-model="shortDescription" class="message-wrap-input" type="text" maxlength="90" />
            <span class="char-counter">{{ shortDescription.length }}/90</span>
          </p>
        </div>
        <div class="message-wrap">
          <span class="message-wrap-title">Текст сообщения</span>
          <p class="input-container">
            <textarea maxlength="1200" v-model="text" class="message-wrap-input-large" />
            <span v-if="errors.text" class="error-message">{{
              errors.text
            }}</span>
          </p>
        </div>
      </div>
      <div class="col">
        <div class="message-wrap">
          <span class="message-wrap-title">Заголовок ссылки</span>
          <p class="input-container">
            <input v-model="titleLink" class="message-wrap-input" type="text" maxlength="45" />
          </p>
          <span v-if="errors.titleLink" class="error-message">{{
            errors.titleLink
          }}</span>
        </div>
        <div class="message-wrap">
          <span class="message-wrap-title">Ссылка</span>
          <p class="input-container">
            <input v-model="link" class="message-wrap-input" type="text" maxlength="90" />
            <span v-if="errors.link" class="error-message">{{
              errors.link
            }}</span>
          </p>
        </div>
        <div class="message-wrap">
          <div class="image-upload-container">
            <div class="product-foto">
              <div class="product-foto__item">
                <picture-input v-if="show_upload" :class="{ visible: show_upload }" ref="pictureInput"
                  accept="image/jpeg,image/png,image/webp" :alertOnError="false" size="10" button-class="btn"
                  :removable="true" :custom-strings="{
                    drag: 'Вставить изображение',
                    change: 'Изменить',
                    remove: 'Удалить',
                  }" @change="onChange" @remove="removeImage">
                </picture-input>

                <div class="preview" :class="{ visible: !show_upload }">
                  <img v-if="logo_url" class="product-foto__item__preview" :src="logo_url" />
                  <button class="image-btn image-btn--replace upload-button" @click.stop.prevent="
                    show_upload = true;
                  $refs.pictureInput.click();
                  ">
                    <img src="../../../../assets/images/icons/upload.svg" alt="Upload" />
                    Добавить обложку
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import PictureInput from "vue-picture-input";

export default {
  components: {
    PictureInput,
  },
  data() {
    return {
      avalableGroups: [
        { label: "Руководителю", value: "manager" },
        { label: "Исполнителю", value: "executor" },
        { label: "Мастеру-приемщику", value: "masterInspector" },
      ],
      show_upload: false,
      groups: [],
      title: "",
      shortDescription: "",
      text: "",
      logo_url: "",
      titleLink: "",
      link: "",
      logo: null,

      errors: {
        groups: null,
        link: null,
        title: null,
        text: null,
        titleLink: null,
      },
    };
  },
  methods: {
    validateForm() {
      let valid = true;

      if (this.groups.length === 0) {
        this.errors.groups = "Выберите хотя бы одну группу.";
        valid = false;
      } else {
        this.errors.groups = null;
      }

      if (this.title.length === 0) {
        this.errors.title = "Обязательное поле";
        valid = false;
      } else {
        this.errors.title = null;
      }

      if (this.text.length === 0) {
        this.errors.text = "Обязательное поле";
        valid = false;
      } else {
        this.errors.text = null;
      }

      if (this.titleLink.length === 0) {
        this.errors.titleLink = "Обязательное поле";
        valid = false;
      } else {
        this.errors.titleLink = null;
      }

      if (this.link && !this.isValidURL(this.link)) {
        this.errors.link = "Введите правильную ссылку.";
        valid = false;
      } else {
        this.errors.link = null;
      }

      if (this.link.length === 0) {
        this.errors.link = "Обязательное поле";
        valid = false;
      } else {
        this.errors.link = null;
      }
      return valid;
    },
    isValidURL(url) {
      try {
        new URL(url);
        return true;
      } catch (_) {
        return false;
      }
    },
    removeImage() {
      this.logo = null;
      this.show_upload = false;
    },
    onChange(image) {
      if (image) {
        this.logo = this.$refs.pictureInput.file;
      }
    },
    submitForm() {
      this.$store.dispatch("getAllAdminMessages");

      if (!this.validateForm()) return;
      
      const formData = new FormData();

      this.groups.forEach((item) => {
        formData.append("groups[]", item.value);
      });
      if (this.title != null && this.title.length > 1)
        formData.append("title", this.title);
      if (this.shortDescription != null && this.shortDescription.length > 1)
        formData.append("shortDescription", this.shortDescription);
      if (this.text != null && this.text.length > 1)
        formData.append("text", this.text);
      if (this.titleLink != null && this.titleLink.length > 1)
        formData.append("titleLink", this.titleLink);
      if (this.link != null && this.link.length > 1) formData.append("link", this.link);
      if (this.logo != null) formData.append("icon", this.logo);

      this.$store
        .dispatch("postAdminMessage", { payload: formData, onSuccess: () => {
          this.$toast("Сообщение успешно добавлено", {
            timeout: 2000,
          });
        },
        onError: () => {
          this.$toast.error("Произошла ошибка при добавлении сообщения", {
            timeout: 2000
          })
        }})
    },
    saveAsDraft(e) {
      e.preventDefault();
      if (!this.validateForm()) return;

      const formData = new FormData();

      this.groups.forEach((item) => {
        formData.append("groups[]", item.value);
      });
      if (this.title != null && this.title.length > 1)
        formData.append("title", this.title);
      if (this.shortDescription != null && this.shortDescription.length > 1)
        formData.append("shortDescription", this.shortDescription);
      if (this.text != null && this.text.length > 1)
        formData.append("text", this.text);
      if (this.titleLink != null && this.titleLink.length > 1)
        formData.append("titleLink", this.titleLink);
      if (this.link != null && this.link.length > 1) formData.append("link", this.link);
      if (this.logo != null) formData.append("icon", this.logo);

      this.$store
        .dispatch("saveAdminMessageAsDraft", { payload: formData,
          onSuccess: () => {
            this.$toast("Сообщение успешно добавлено", {
            timeout: 2000
          });
          this.$store.dispatch("getAllAdminMessages");
          },
          onError: () => {
          this.$toast.error("Произошла ошибка при добавлении сообщения", {
            timeout: 2000
          });
         },
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  color: red;
  font-size: 0.875em;
  font: 400 12px/1 $roboto;
}

.input-container {
  position: relative;
}

.message-wrap-input {
  border: none;
  background-color: $gray;
  height: 30px;
  width: calc(100% - 50px);
  padding-right: 50px;
}

.message-wrap-input-large {
  border: none;
  background-color: $gray;
  height: 150px;
  width: 100%;
  padding-right: 50px;
  resize: none;
}

.char-counter {
  font: 400 12px/1 $roboto;
  color: $black;
  opacity: 0.6;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: $gray;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
}

.message-wrap {
  margin-bottom: 25px;

  &-input {
    border: none;
    background-color: $gray;
    height: 37px;
    width: -webkit-fill-available;
  }

  &-title {
    font: 400 14px/1.6 $roboto;
  }

  &-logo {
    display: flex;
    flex-direction: column;
  }

  &-img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 7px;
  }
}

.messages-form-list-btns {
  display: flex;
  gap: 10px;
  margin-top: 20px;

  .add {
    margin-right: auto;

    &:disabled {
      pointer-events: none;
      cursor: not-allowed;
      background: lighten($admin-blue, 30%);
    }
  }

  .remove,
  .save {
    margin-left: auto;
  }

  .messages-form-list-btn {
    display: block;
    height: 40px;
    padding: 0 15px;
    color: $white;
    font: 400 14px/8px $roboto, sans-serif;
    background: $admin-blue;
    border: none;
    cursor: pointer;

    &:hover {
      background: lighten($admin-blue, 10%);
    }
  }

  .messages-form-list-dbtn {
    display: block;
    height: 40px;
    padding: 0 15px;
    color: $white;
    font: 400 14px/8px $roboto, sans-serif;
    background: $admin-red;
    border: none;
    cursor: pointer;

    &:disabled {
      pointer-events: none;
      cursor: not-allowed;
      background: lighten($admin-red, 30%);
    }

    &:hover {
      background: lighten($admin-red, 10%);
    }
  }
}

.image-details {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.admin-messages-form {
  margin-top: 30px;

  .col {
    width: 33.33%;
  }
}

.admin-messages-head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
}

.admin-messages-heading {
  margin-top: 30px;
  margin-bottom: 10px;
  font: 700 32px/1.5 $roboto;
  color: $dark-font;
}

.admin-messages-heading-form {
  margin-top: 30px;
  margin-bottom: 10px;
  font: 400 24px/1.5 $roboto;
  color: $dark-font;
}

.image-upload-container {
  margin-top: 20px;
}

.product-foto {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: 200px;
    cursor: pointer;
    position: relative;

    &__preview {
      max-width: 198px;
      max-height: 198px;
    }
  }
}

.preview {
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  display: none;

  &.visible {
    display: flex;
  }
}

.upload-button {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  background-color: inherit !important;
  color: $admin-blue;
  border: none;
  padding: 10px 1px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.image-preview {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.image-item {
  position: relative;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.image-item img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 5px;
}

.image-description {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 3px;
}

.image-name {
  font: 400 14px/1 $roboto;
  color: $admin-blue;
}

.image-bottom-btn {
  background-color: $admin-blue;
  color: $white;
  border: none;
  padding: 6px;
  cursor: pointer;
}

.image-close {
  &:hover {
    cursor: pointer;
  }
}
</style>
