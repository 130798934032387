<template>
  <div class="history">
    <div class="history-main">
      <div class="history-container">
        <h1 class="history-heading">Баланс: {{ balance.data.balance }} баллов</h1>

        <h2 class="history-heading-md">Скоро сгорят</h2>
        <div class="history-list" v-for="(item, idx) in history.data.expiringBonuses" :key="idx">
          <div class="history-list__item">
            <p class="history-list__date">{{ formatDate(item.burnDate) }}</p>
          </div>
          <div class="history-list__amount">{{ item.sum }} МБ</div>
        </div>

        <h2 class="history-heading-history">История</h2>
        <div class="history-list" v-for="(item, idx) in history.data.bonusHistory" :key="idx">
          <div class="history-list__item">
            <div class="history-list__type">
              {{ item.description }}
              <span v-if="item.order" class="history-list__badge">{{ item.order.status }}</span>
            </div>
            <p class="history-list__desc">{{ formatDate(item.createdAt.split('T')[0]) }}</p>
            <p class="history-list__desc" v-if="item.order">{{ item.order.purchases.goods[0].name }}</p>
          </div>
          <div class="history-list__amount" :class="{ 'green': !item.order && item.sum >= 0, 'minus': item.order && item.sum >= 0  }">{{ item.sum }} МБ</div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
export default {
  name: 'history',
  data() {
    return {
      burned: null,
      historyData: null
    }
  },

  methods: {
    formatDate(date) {
      const parts = date.split('-');
      const day = parts[2];
      const month = parts[1];
      const year = parts[0];
      const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
      return `${day} ${months[month - 1]} ${year}`;
    }
  },

  computed: {
    ...mapState({
      history: state => state.history.history,
      balance: state => state.auth.balance,
    })

  },

  created() {
    this.$store.dispatch('fetchHistory')

    this.historyData = this.history.data.bonusHistory.map(({ order }) => {
      return order
    })

    console.log(this.historyData, 'historyData');

  },

}
</script>

<style lang="scss" scoped>
.history-main {
  background-color: #1A1C1C;
  position: relative;
  z-index: 1;
  padding-bottom: 100px;
  min-height: 100vh;

  @include display-less(tablet) {
    padding-bottom: 120px;
  }
}

.history-list {
  display: flex;
  flex-direction: row;
  color: #FFFEFA;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__badge {
    font: 400 15px/26px $roboto;
    padding: 3px 8px;
    background-color: #64C464;
    border-radius: 4px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  &__type {
    font: 400 20px/26px $roboto;
  }


  &__date {
    font: 400 20px/26px $roboto;
  }

  &__desc {
    font: 200 15px/26px $roboto;
  }

  &__amount {
    font: 400 20px/26px $roboto;
    text-align: end;
  }
}

.history-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;
  width: 100%;
  max-width: 640px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @include display-less(phablet) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include display-less(smart) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.history-heading {
  font: 700 32px/26px $roboto;
  color: #FFFEFA;
  padding-top: 38px;
  margin-bottom: 40px;
  text-align: center;

  &-history {
    margin-top: 40px;
    margin-bottom: 30px;
    font: 700 30px/26px $roboto;
    color: #FFFEFA;
  }


  &-md {
    font: 500 24px/26px $roboto;
    line-height: 32px;
    color: #FFFEFA;
    padding-top: 38px;
    margin-bottom: 20px;
    text-align: center;
  }

}

.green {
  color: #64C464;

  &::before {
    content: '+';
  }
}

.minus {
  color: #FFFEFA;
  
  &::before {
    content: '- ';
  }
}
</style>