<template>
    <div class="col" :class="classes">
        <div class="profile-input-wrap">
            <input
                v-mask="mask"
                v-bind:value="value"
                v-on:input="input($event.target.value)"
                v-on:focus="dropDownSuggestions"
                v-on:blur="slideUpSuggestions"
                :class="{active: value.length}"
                class="profile-form__input"
                :placeholder="placeholder"
            >
            <dadata-suggestions
                v-if="showSuggestions"
                :query="query ? query : value"
                :bound="bound"
                :data-part="dataPart"
                v-on:set-value="setValue($event)"
            />
            <span class="profile-form__clear" @click="clearData()"></span>
        </div>
    </div>
</template>

<script>
import DadataSuggestions from "./DadataSuggestions";

export default {
    components: { DadataSuggestions },
    props: {
        value: null,
        classes: null,
        mask: null,
        placeholder: null,
        bound: null,
        dataPart: null,
        query: null,
        setIndex: null
    },
    data() {
        return {
            showSuggestions: false
        };
    },
    methods: {
        dropDownSuggestions() {
            this.showSuggestions = true;
        },

        slideUpSuggestions() {
            setTimeout(() => this.showSuggestions = false, 300);
        },

        clearData() {
            this.input('');
        },

        setValue(data) {
            this.showSuggestions = false;

            if (data.data.hasOwnProperty('region_with_type') && data.data.region_with_type) {
                this.$store.commit('setSuggestedRegion', data.data.region_with_type);
            }

            if (this.setIndex && data.data.hasOwnProperty('region_with_type') && data.data.postal_code) {
                this.$store.commit('setSuggestedIndex', data.data.postal_code);
            }

            let value = data.data[this.dataPart];
            if (this.dataPart === 'house') {
                value = [
                    data.data[this.dataPart],
                    data.data.block_type,
                    data.data.block
                ].join(' ').trim();
            }

            this.input(value);
        },

        input(value) {
            this.$emit('input', value);
        }
    }
}
</script>