import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../../pages/Login.vue'
import Home from '../../pages/Home.vue'
import Profile from '../../pages/Profile.vue'
import Tasks from '../../pages/Tasks.vue'
import Basket from '../../pages/Basket.vue'
import Products from '../../pages/Products.vue'
import Product from '../../pages/Product.vue'
import Messages from '../../pages/Messages.vue'
import Instructions from '../../pages/instructions.vue'
import History from '../../pages/History.vue'

// админка
import AdminLogin from '../../pages/AdminLogin.vue'
import Admin from '../../pages/Admin.vue'
import Users from '../../apps/Admin/Users.vue'
import Orders from '../../apps/Admin/Orders/Orders.vue'
import ImageViewer from '../../apps/Admin/ImageViewer.vue'
import AdminCatalog from '../../apps/Admin/AdminCatalog.vue'
import AdminTasks from '../../apps/Admin/AdminTasks.vue'
import Access from '../../apps/Admin/Access.vue'
import AdminMessages from '../../apps/Admin/AdminMessages/AdminMessages.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: { loggedIn: true }
  },
  {
    path: '/catalog/:id',
    name: 'products',
    component: Products,
    props: {
      layout: "DefaultLayout"
    },
    meta: { requiresAuth: true }
  },
  {
    path: '/catalog-custom/:id',
    name: 'products-custom',
    props: {
      customCatalog: true,
      layout: "DefaultLayout"
    },
    component: Products,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/catalog/:id/:product',
    name: 'detailed',
    component: Product,
    props: {
      layout: "DefaultLayout"
    },
    meta: { requiresAuth: true }
  },
  {
    path: '/catalog-custom/:id/:product',
    name: 'detailed-custom',
    props: {
      customCatalog: true,
      layout: "DefaultLayout"
    },
    component: Product,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/home',
    name: 'home',
    props: {
      layout: "DefaultLayout"
    },
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    props: {
      layout: "DefaultLayout"
    },
    meta: { requiresAuth: true }
  },
  {
    path: '/tasks',
    name: 'tasks',
    props: {
      layout: "DefaultLayout"
    },
    component: Tasks,
    meta: { requiresAuth: true }
  },
  {
    path: '/basket',
    name: 'basket',
    props: {
      layout: "DefaultLayout"
    },
    component: Basket,
    meta: { requiresAuth: true }
  },
  {
    path: '/instructions',
    name: 'instructions',
    props: {
      layout: "DefaultLayout"
    },
    component: Instructions,
    meta: { requiresAuth: true }
  },
  {
    path: '/history',
    name: 'history',
    props: {
      layout: "DefaultLayout"
    },
    component: History,
    meta: { requiresAuth: true }
  },
  {
    path: '/messages',
    name: 'messages',
    props: {
      layout: "DefaultLayout"
    },
    component: Messages,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin/login',
    component: AdminLogin,
    name: 'adminLogin',
    meta: { loggedAdmin: true }
  },
  {
    path: '/admin',
    component: Admin,
    name: 'admin',
    meta: { requiresAdminAuth: true },
    children: [
      {
        path: 'users',
        name: 'adminUsers',
        component: Users,
        meta: { requiresAdminAuth: true },
      },
      {
        path: 'orders',
        name: 'adminOrders',
        component: Orders,
        meta: { requiresAdminAuth: true },
      },
      {
        path: 'images',
        name: 'adminImageViewer',
        component: ImageViewer,
        meta: {requiresAdminAuth: true },
      },
      {
        path: 'catalog',
        name: 'adminCatalog',
        component: AdminCatalog,
        meta: { requiresAdminAuth: true },
      },
      {
        path: 'tasks',
        name: 'adminTasks',
        component: AdminTasks,
        meta: { requiresAdminAuth: true },
      },
        {
        path: 'messages',
        name: 'adminMessages',
        component: AdminMessages,
        meta: { requiresAdminAuth: true }
      },
      {
        path: 'access',
        name: 'adminAccess',
        component: Access,
        meta: { requiresAdminAuth: true },
      }
    ]
  },
  {
    path: "*",
    redirect: '/home'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') == null) {
      next({
        path: '/',
        params: { nextUrl: to.fullPath }
      })
    }
    else {
      next()
    }
  }
  next()

  if(to.matched.some(record => record.meta.requiresAdminAuth)) {
    if (localStorage.getItem('jwt-admin') == null) {
      next({
        path: '/admin/login',
        params: { nextUrl: to.fullPath }
      })
    }else {
      next()
    }
  }
  next()

  if(to.matched.some(record => record.meta.loggedIn)){
    if (localStorage.getItem('jwt') != null) {
      next({
        path: '/home',
        params: { nextUrl: to.fullPath }
      })
    }else {
      next()
    }
  }
  next()

  if(to.matched.some(record => record.meta.loggedAdmin)){
    if (localStorage.getItem('jwt-admin') != null) {
      next({
        path: '/admin/users',
        params: { nextUrl: to.fullPath }
      })
    }else {
      next()
    }
  }
  next()
})


export default router
