<template>
  <transition name="modal">
    <div class="modal" v-if="visible">
      <div class="modal__content">
        <div class="modal__header">
          <div class="modal__title">
            <h2>{{ item.title }}</h2>
          </div>
          
          <button @click="closeModal">
            <img class="modal__close" src="../../assets/images/icons/close.svg" alt="close" />
          </button>
        </div>
        <div class="modal__body">
          <img class="modal__icon" v-if="item.icon" :src=item.icon alt="icon" />
          <img class="modal__icon" v-else :src="getIconUrl(item.type)" alt="icon" />
          <div class="modal__text">
            <div class="modal__description" v-if="item.text">{{ item.text }}</div>
            <a target="_blank" v-if="item.link" :href="item.link" class="modal__link">{{ item.titleLink }}</a>
            <button class="modal__close-button" @click="closeModal">Закрыть</button>
          </div>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    getIconUrl(icon) {
      const icons = {
        new_task: require('../../assets/images/icons/warning-icon.svg'),
        important_news: require("../../assets/images/icons/warning-icon.svg"),
        change_order_status: require('../../assets/images/icons/delivery-icon.svg')
      };
      return icons[icon]
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.551);
  z-index: 1000;

  &__close {
    &:hover {
      opacity: 0.7;
    }
  }

  &__icon {
    width: 101px;
    height: 101px;
    margin-bottom: 10px;
  }
}

.modal__content {
  background-color: #232524;
  padding: 20px;
  border-radius: 8px;
  width: 395px;
  max-width: 500px;
  position: relative;
  max-height: 90vh;

  @include display-less(tablet) {
    width: 100%;
    position: absolute;
    bottom: inherit;
    border-radius: 30px;
  }
}

.modal__header {
  font: 400 24px/32px $roboto;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  text-align: start;
}

.modal__title {
  word-wrap: break-word;
  word-break: break-word;
}

.modal__descr {
  word-wrap: break-word;
  word-break: break-all;
}

.modal__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal__header h2 {
  margin: 0;
}

.modal__header button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal__body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modal__icon {
  border-radius: 4px;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.modal__description {
  font: 400 14px/19px $roboto;
  color: #fff;
  margin-bottom: 10px;
  text-align: left;
  word-wrap: break-word;
  word-break: break-word;
  max-height: 114px;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal__link {
  font: 500 14px/26px $roboto;
  color: #3E93C2;
  text-decoration: none;
  display: block;
  margin-bottom: 20px;
}

.modal__close-button {
  margin-top: 10px;
  background-color: #F47D32;
  width: 202px;
  height: 48px;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;

  @include display-less(tablet) {
    display: none;
  }

  &:hover {
    background-color: darken(#F47D32, 10%);
  }
}



.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}
</style>
