<template>
  <div class="bottom-submenu-inner">
    <div class="bottom-submenu-title" @click="submenuClose">Личный кабинет</div>
    <slick :options="slickCabinetOptions">
      <ul class="bottom-submenu-nav" v-for="(slide, idx) in chunkCabinetMenu" :key="idx">
        <!-- <li v-for="(link, idx) in slide" :key="idx" class="bottom-submenu-nav__item">
          <router-link :to="link.path">{{ link.title }}</router-link>
        </li>  -->
        <li class="bottom-submenu-nav__item">
          <router-link to="/profile">профиль</router-link>
        </li> 
        <li class="bottom-submenu-nav__item">
          <router-link to="/history">история</router-link>
        </li> 
        <li class="bottom-submenu-nav__item">
          <span @click="logout">выйти</span>
        </li> 
      </ul>
    </slick>
  </div>
</template>


<script>
  import Slick from 'vue-slick';
  import 'slick-carousel/slick/slick.css';
  import chunkArray from '@/utils/chankArray'
  import { EventBus } from '@/main.js';

  export default {
    data() {
      return {
        cabinetMenu: [
          {
            "title": "профиль",
            "path": "/profile"
          },
          {
            "title": "история",
            "path": "/history"
          },
          {
            "title": "выйти",
            "path": "#"
          }
        ]
      }
    },

    computed: {
      chunkCabinetMenu() {
        return chunkArray(this.cabinetMenu, 3) 
      },

      slickCabinetOptions() {
        return {
          slidesToShow: 1,
          arrows: true,
          infinite: false,
          autoplay: false
        }
      }
    },

    methods: {
      submenuClose() {
        EventBus.$emit("submenuClose", {"submenuClose": null});
      },

      logout() {
        localStorage.removeItem('jwt')
        this.$router.go()
      },
    },

    components: { 
      Slick
    }
  }
</script>


