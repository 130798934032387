<template>
    <div class="users-wrap">
        <div class="modal" v-if="popupIsActive">
            <div class="category-popup" v-if="userBlockPopup">
                <div class="modal__title">Вы действительно хотите заблокировать пользователя «{{ userName }}»?</div>
                <div class="modal-btns">
                    <button class="modal-btn delete"
                        @click="blockUser(); popupIsActive = false; userBlockPopup = false;">
                        Заблокировать
                    </button>
                    <button class="modal-btn decline" @click="
                        popupIsActive = false;
                    userBlockPopup = false;
                    ">
                        Отмена
                    </button>
                </div>
            </div>
        </div>

        <div class="users-top">
            <div class="users-search">
                <h1 class="users-heading">Пользователи</h1>
                <form action="" class="user-search">
                    <div class="input-wrap">
                        <label>
                            <input class="user-search__input by-name" type="text" placeholder=" " v-model="name"
                                @keyup="searchUser" @keyup.enter="searchUser">
                            <span>Поиск по имени</span>
                        </label>
                    </div>

                    <div class="input-wrap">
                        <label>
                            <input class="user-search__input by-phone" placeholder=" " v-model="phone"
                                @keyup="searchUser" @keyup.enter="searchUser">
                            <span>Поиск по телефону</span>
                        </label>
                    </div>

                    <div class="input-wrap">
                        <label>
                            <span>Поиск по статусу</span>
                        </label>
                        <div class="users-select">
                            <v-select placeholder="Поиск по статусу" class="admin-select" :options="statusData"
                                label="name" @input="searchUser" v-model="status">
                            </v-select>

                        </div>
                    </div>
                </form>
            </div>
            <div class="users-info">
                <div class="users-info-block">
                    <div class="users-info-block__title">Количество записей в системе:</div>
                    <div class="users-info-block__value">{{ stats.allUserCount }}</div>
                </div>
                <div class="users-info-block">
                    <div class="users-info-block__title">Авторизованых пользователей:</div>
                    <div class="users-info-block__value">{{ stats.authenticatedUsersCount }}</div>
                </div>
                <div class="users-info-block">
                    <div class="users-info-block__title">Заполненых профилей:</div>
                    <div class="users-info-block__value">{{ stats.completedProfilesCount }}</div>
                </div>
            </div>
        </div>
        <div class="user-btn-download row">
            <div class="col">
                <button @click="downloadUserData" class="button">Выгрузить данные пользователей</button>
            </div>
        </div>
        <div class="user-search-result-wrap">
            <div class="user-search-result-head">
                <div class="col user-search-result-head__name">Имя</div>
                <div class="col user-search-result-head__phone">Номер телефона</div>
                <div class="col user-search-result-head__balance">Баланс</div>
                <div class="col">Статус профиля</div>
            </div>
            <div class="search-result-wrap" @scroll="onScroll">
                <div class="list-wrap">
                    <div class="user-search-result" v-for="(user, idx) in users" :key="idx"
                        :class="{ active: user.id == selected, blocked: user.blocked }"
                        @click="getUserId(user.id); selected = user.id">
                        <div class="col user-search-result__name">{{ user.full_name }}</div>
                        <div class="col user-search-result__phone">{{ convert(user.phone) }}</div>
                        <div class="col user-search-result__balance">{{ user.account ? user.account.balance : 0 }}</div>
                        <div class="col user-search-result__balance">{{ getUserStatus(user) }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row user-btn-edit" v-if="selected != undefined && users.length > 0">
            <div class="col">
                <button v-if="!isBlocked" @click="popupIsActive = true; userBlockPopup = true">Блокировать</button>
                <button v-if="isBlocked" @click="unBlockUser">Разблокировать</button>
            </div>
            <div class="col">
                <button @click="enterAsUser">Войти как пользователь</button>
            </div>
        </div>

        <div class="user-edit" v-if="selected != undefined && users.length > 0">
            <div class="row">
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="userName">
                            <span>Фио</span>
                        </label>
                    </div>
                </div>
            </div>
            <div v-if="userData != null && userData.photo !== null" class="row">
                <a class="col snils-photo" v-on:click="openPhoto(userData.photo)">
                    <label for="imgs">Фото снилса</label>
                    <img id="imgs" v-bind:src="imageContent" />
                </a>
                <div class="col user-btn-edit" style="margin-top: 10px">
                    <a v-bind:href="imageContent" v-bind:download="'snils.' + extension">Скачать СНИЛС</a>
                </div>
            </div>

            <div v-if="userData != null && userData.snils != null" class="row">
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-mask="snilsMask"
                                v-model="userData.snils">
                            <span>СНИЛС</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col" v-if="userData != null && userData.birth_date != null">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-mask="birthDateMask"
                                v-model="userData.birth_date">
                            <span>Дата рождения</span>
                        </label>
                    </div>
                </div>

                <div class="col" v-if="userData != null && userData.inn != null">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-mask="innDateMask"
                                v-model="userData.inn">
                            <span>ИНН</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="users-select">
                        <v-select class="admin-select" :class="{ active: nationality }" :options="countries"
                            label="name" v-model="nationality">
                            <div slot="no-options">Выберете из списка</div>
                        </v-select>
                        <label>
                            <span>Страна</span>
                        </label>
                    </div>
                </div>
                <div class="col" v-if="nationality && nationality.code === 'kz'">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="addressRegister">
                            <span>Адрес регистрации</span>
                        </label>
                    </div>
                </div>
            </div>


            <div v-if="nationality && nationality.code !== 'kz'" class="row">
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="address_city">
                            <span>Город</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="street">
                            <span>Улица</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="houseNumber">
                            <span>Номер дома</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="apartment">
                            <span>Квартира</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="building">
                            <span>Здание</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="build">
                            <span>Корпус</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="structure">
                            <span>Строение</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="possession">
                            <span>Владение</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="index">
                            <span>Индекс</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="region">
                            <span>Регион</span>
                        </label>
                    </div>
                </div>
            </div>

            <div v-if="userData != null && userData.photoPassport !== null" class="row">
                <a class="col snils-photo" v-on:click="openPhotoPassport(userData.photoPassport)">
                    <label for="imgs">Фото паспорта</label>
                    <img id="imgs" v-bind:src="imagePassportContent" />
                </a>
                <div class="col user-btn-edit" style="margin-top: 10px">
                    <a v-bind:href="imagePassportContent" v-bind:download="'passport.' + passportExtension">Скачать
                        паспорт</a>
                </div>
            </div>

            <div class="row" v-if="nationality">
                <div class="col">
                    <div v-if="nationality.code != 'kz'" class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-mask="passportSeriesMask"
                                v-model="passportSeries">
                            <span>Серия паспорта</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" "
                                v-mask="(nationality.code != 'kz') ? passportNumMask : passportKzNumMask"
                                v-model="passportNum">
                            <span>{{ nationality.code != 'kz' ? 'Номер паспорта' : 'Удостоверение личности' }}</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-mask="passportDateMask"
                                v-model="passportDate">
                            <span>Когда выдан</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="passportIssued">
                            <span>Кем выдан</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div v-if="nationality" class="col">
                    <div v-if="nationality.code != 'kz'" class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-mask="passportCodeMask"
                                v-model="passportCode">
                            <span>Код подразделения</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="input-wrap">
                        <label>
                            <input class="user-edit__input" type="text" placeholder=" " v-model="phoneNumber">
                            <span>Номер телефона</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="users-select">
                        <v-select class="admin-select " label="name" :class="{ active: stationsSelected }"
                            :options="cities" @input="changeCity" v-model="citySelected">
                            <div slot="no-options">Нет такого города</div>
                        </v-select>
                        <label>
                            <span>Город</span>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="users-select">
                        <v-select class="admin-select" :class="{ active: stationsSelected }" label="address"
                            :options="stationsFiltered" :disabled="stationsFiltered.length == 0"
                            v-model="stationsSelected">
                            <div slot="no-options">Нет такой станции</div>
                        </v-select>
                        <label>
                            <span>Станция</span>
                        </label>
                    </div>
                </div>
            </div>

            <div id="confirmLine" class="row d-flex-justify-content-center" style="display: none">
                Данные пользователя подтверждены
            </div>
            <div id="unconfirmLine" class="row d-flex-justify-content-center" style="display: none">
                Данные пользователя отклонены
            </div>
            <div class="row">
                <div class="col">
                    <button class="user-edit-save" @click="updateUser">Сохранить</button>
                </div>
                <div class="col">
                    <button class="user-edit-save" @click="confirmUser">Проверено</button>
                    <button style="margin-left: 5px" class="user-edit-save" @click="unconfirmUser">Отклонить
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import 'vue-select/dist/vue-select.css'
import axios from "axios";
import convertPhone from "../../utils/convertPhone";

export default {
    data() {
        return {
            name: '',
            phone: '',
            status: '',
            statusData: [
                { id: null, name: "Все" },
                { id: 0, name: "Не заполнен" },
                { id: 1, name: "Проверен" },
                { id: 2, name: "Отклонен" },
                { id: 3, name: "На проверке" }
            ],
            searchIsActive: false,
            convertedPhone: '',
            offset: 0,
            limit: 10,
            selected: undefined,
            isBlocked: false,
            userId: null,
            userName: '',
            addressRegister: '',
            address_city: '',
            street: '',
            houseNumber: '',
            index: '',
            region: '',
            possession: '',
            building: '',
            build: '',
            structure: '',
            apartment: '',
            passportSeries: '',
            passportNum: '',
            passportDate: '',
            passportIssued: '',
            passportCode: '',
            phoneNumber: '',
            inn: '',
            snils: '',
            stationOptions: [],
            citySelected: null,
            stationsSelected: null,
            nationality: null,
            userBlockPopup: false,
            popupIsActive: false,

            maskName: "X".repeat(60),
            passportSeriesMask: "####",
            passportNumMask: "######",
            passportKzNumMask: "#########",
            passportDateMask: "##-##-####",
            innDateMask: "####-######-##",
            birthDateMask: "##-##-####",
            snilsMask: "###-###-### ##",
            passportCodeMask: "###-###",

            imageContent: "",
            extension: "",
            imagePassportContent: "",
            passportExtension: ""
        }
    },

    computed: {
        ...mapState({
            users: state => state.users.users,
            userData: state => state.users.user,
            cities: state => state.profile.cities,
            stations: state => state.profile.stations,
            userToken: state => state.authAdmin.userToken,
            stats: state => state.users.stats,
            usersDataToDownload: state => state.users.usersDataToDownload,
            countries: state => state.profile.countries,
            stationsFiltered() {
                return (this.citySelected)
                    ? this.citySelected.stations
                    : [];
            },
        })
    },

    watch: {
        userToken() {
            if (this.userToken) {
                localStorage.setItem('jwt', this.userToken)
                let routeData = this.$router.resolve({ path: '/' })
                window.open(routeData.href, '_blank')
            }
        },

        stations() {
            if (this.stations) {
                this.stationOptions = this.stations
            }
        },

        searchIsActive() {
            if (this.searchIsActive === false) {
                this.$store.dispatch('searchUsers', {
                    name: "",
                    phone: "",
                    status: null,
                    offset: this.offset,
                    limit: this.limit
                })
            }
        },

        userData() {
            this.phoneNumber = this.userData.phone
            this.userData.full_name != null ? this.userName = this.userData.full_name : this.userName = ""
            this.nationality = this.userData.country
            this.userData.city != null ? this.citySelected = this.userData.city : this.citySelected = null
            this.userData.station != null ? this.stationsSelected = this.userData.station.address : this.stationsSelected = null
            this.userData.address != null ? this.addressRegister = this.userData.address : this.addressRegister = ""
            this.userData.passport_serie != null ? this.passportSeries = this.userData.passport_serie : this.passportSeries = ""
            this.userData.passport_number != null ? this.passportNum = this.userData.passport_number : this.passportNum = ""
            this.userData.passport_emit_date != null ? this.passportDate = this.revertDate(this.userData.passport_emit_date) : this.passportDate = ""
            this.userData.emitter_code != null ? this.passportCode = this.userData.emitter_code : this.passportCode = ""
            this.userData.emitter != null ? this.passportIssued = this.userData.emitter : this.passportIssued = ""
            this.isBlocked = this.userData.blocked
            this.userData.birth_date = this.revertDate(this.userData.birth_date);
            if (this.userData.photo !== "") {
                this.openPhoto();
            }

            if (this.userData.photoPassport !== "") {
                this.openPhotoPassport();
            }

            this.address_city = this.userData.address_city != null ? this.userData.address_city : '';
            this.street = this.userData.street != null ? this.userData.street : '';
            this.houseNumber = this.userData.houseNumber != null ? this.userData.houseNumber : '';
            this.index = this.userData.index != null ? this.userData.index : '';
            this.region = this.userData.region != null ? this.userData.region : '';
            this.possession = this.userData.possession != null ? this.userData.possession : '';
            this.building = this.userData.building != null ? this.userData.building : '';
            this.build = this.userData.build != null ? this.userData.build : '';
            this.structure = this.userData.structure != null ? this.userData.structure : '';
            this.apartment = this.userData.apartment != null ? this.userData.apartment : '';
        },

        // получение файла выгрузки поставщика
        usersDataToDownload: {
            handler(val) {
                if (val) {
                    this.createLink(
                        val,
                        'application/vnd.ms-excel',
                        `Пользователи ${(new Date()).toISOString()}.xlsx`
                    )
                }
            },
            deep: true
        }
    },

    methods: {
        getUserStatus(user) {
            switch (user.verified) {
                case 1:
                    return "Проверен";
                case 2:
                    return "Отклонен";
                case 3:
                    return 'На проверке';
                default:
                    return 'Не заполнен';
            }
        },
        openPhoto() {
            let token = "Bearer " + localStorage.getItem('jwt-admin');
            if (token === "") {
                return;
            }
            axios.get(process.env.VUE_APP_baseURL + "/" + this.userData.photo, {
                headers: {
                    "Authorization": token
                }
            }).then((res) => {
                this.imageContent = res.data;
                this.extension = res.data.split(';')[0].split(':image/')[1];
            });
        },
        openPhotoPassport() {
            let token = "Bearer " + localStorage.getItem('jwt-admin');
            if (token === "") {
                return;
            }
            axios.get(process.env.VUE_APP_baseURL + "/" + this.userData.photoPassport, {
                headers: {
                    "Authorization": token
                }
            }).then((res) => {
                this.imagePassportContent = res.data;
                this.passportExtension = res.data.split(';')[0].split(':image/')[1];
            });
        },
        searchUser() {
            this.offset = 0
            this.limit = 10
            if (this.phone != "") {
                this.convertedPhone = parseInt(this.phone.replace(/\+7|[^0-9]/g, ''), 10)
                if (isNaN(this.convertedPhone)) {
                    this.convertedPhone = ""
                }
            }

            if (this.phone !== "" || this.name !== "") {
                this.searchIsActive = true
            } else {
                this.searchIsActive = false
            }

            this.$store.dispatch('searchUsers', {
                name: this.name,
                phone: this.convertedPhone,
                status: this.status?.id,
                offset: this.offset,
                limit: this.limit
            })
        },

        onScroll(event) {
            let wrapper = event.target,
                list = wrapper.firstElementChild

            let scrollTop = wrapper.scrollTop,
                wrapperHeight = wrapper.offsetHeight,
                listHeight = list.offsetHeight

            let diffHeight = listHeight - wrapperHeight
            if (diffHeight == scrollTop && (this.offset + 10) == this.users.length) {
                this.offset += 10

                this.$store.dispatch('searchUsers', {
                    name: this.name,
                    phone: this.convertedPhone,
                    status: this.status?.id,
                    offset: this.offset,
                    limit: this.limit
                })
            }
        },

        getUserId(id) {
            this.userId = id
            this.$store.dispatch('getUserById', id)
        },

        enterAsUser() {
            localStorage.removeItem('jwt')
            this.$store.dispatch('loginAdminAsUser', this.userId)
        },

        blockUser() {
            this.popupIsActive = false
            this.userBlockPopup = false
            this.$store.dispatch('blockUser', this.userId).then(() => {
                this.fetchAgain()
            })
        },

        unBlockUser() {
            this.$store.dispatch('unBlockUser', this.userId).then(() => {
                this.fetchAgain()
            })
        },

        fetchAgain() {
            setTimeout(() => {
                this.$store.dispatch('getUserById', this.userId).then(() => {
                    this.$store.dispatch('searchUsers', {
                        name: this.name,
                        phone: this.convertedPhone,
                        status: this.status?.id,
                        offset: this.offset,
                        limit: this.limit
                    })
                })
            }, 200);
        },

        confirmUser() {
            let url = process.env.VUE_APP_baseURL + "/api/user/" + this.userId + "/confirmation";
            let token = "Bearer " + localStorage.getItem('jwt-admin');
            axios.get(url, {
                headers: {
                    "Authorization": token
                }
            }).then((response) => {
                if (response.data.status == "OK") {
                    let alertLine = document.querySelector('#confirmLine');
                    alertLine.style.display = "block";
                    this.searchUser();
                    setTimeout(() => {
                        alertLine.style.display = "none";
                    }, 2000)
                }
            });
        },

        unconfirmUser() {
            let url = process.env.VUE_APP_baseURL + "/api/user/" + this.userId + "/unconfirmation";
            let token = "Bearer " + localStorage.getItem('jwt-admin');
            axios.get(url, {
                headers: {
                    "Authorization": token
                }
            }).then((response) => {
                if (response.data.status == "OK") {
                    let alertLine = document.querySelector('#unconfirmLine');
                    alertLine.style.display = "block";
                    this.searchUser();
                    setTimeout(() => {
                        alertLine.style.display = "none";
                    }, 2000)
                }
            });
        },

        updateUser() {
            let profileData = {
                "full_name": this.userName,
                "countryId": this.nationality && this.nationality.id,
                "address": this.addressRegister,
                "passport_serie": this.passportSeries,
                "passport_number": this.passportNum,
                "passport_emit_date": this.revertDate(this.passportDate) || undefined,
                "emitter": this.passportIssued,
                "emitter_code": this.passportCode,
                "phone": this.phoneNumber,
                "cityId": this.citySelected && this.citySelected.id,
                "stationId": this.stationsSelected && this.stationsSelected.id,
                'photo': this.userData.photo,
                'photoPassport': this.userData.photoPassport,
                "snils": this.userData.snils,
                "inn": this.userData.inn,
                "birth_date": this.revertDate(this.userData.birth_date),
                "address_city": this.address_city,
                "street": this.street,
                "houseNumber": this.houseNumber,
                "index": Number(this.index),
                "region": this.region,
                "possession": this.possession,
                "building": this.building,
                "build": this.build,
                "structure": this.structure,
                "letter": this.letter,
                "apartment": this.apartment,
            }


            this.$store.dispatch('updateUserAdmin', {
                id: this.userId, profileData: profileData, onSuccess: () => {
                    this.$toast("Сообщение успешно добавлено", {
                        timeout: 2000
                    })
                     this.fetchAgain();
                },
                onError: () => {
                    this.$toast.error("Произошла ошибка", {
                        timeout: 2000
                    });
                }
            })
        },

        // Функция преобразования формата даты
        revertDate(date_line) {
            if (date_line == null) {
                return null
            }
            
            let sp = date_line.split("-").reverse();
            return sp.join("-");
        },

        convert(num) {
            return convertPhone(num)
        },

        changeCity() {
            this.stationsSelected = null
        },

        downloadUserData() {
            this.$store.dispatch('getUserData');
        },

        createLink(blob, type, name) {
            const fileURL = URL.createObjectURL(new Blob([blob], { type: type }))
            let fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', name)
            document.body.appendChild(fileLink)
            fileLink.click()
            document.body.removeChild(fileLink);
        }
    },

    created() {
        window.addEventListener('scroll', this.onScroll);

        this.$store.dispatch('fetchCitiesAdmin')
        this.$store.dispatch('fetchStationsAdmin')
        this.$store.dispatch('fetchCountriesAdmin')
        this.$store.dispatch('getStats')
        this.searchUser()

        // Получение параметров запроса. В случае если запрос включает user_id открываем карточку пользователя
        let queryParams = new URLSearchParams(window.location.search);
        let params = Object.fromEntries(queryParams.entries());

        if (params.user_id !== undefined) { // Открытие карточки пользователя
            this.getUserId(params.user_id);
            this.selected = params.user_id;
        }

    },

    destroyed() {
        window.removeEventListener('scroll', this.onScroll);
    }
}
</script>

<style lang="scss" scoped>
.snils-photo>img {
    max-width: 300px;
    max-height: 300px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.users-wrap {
    background: #fff
}

.users-top {
    display: flex;
    margin-top: 40px;
}

.users-search {
    width: 36.7%;
}

.users-info {
    width: 63.3%;
}

.users-heading {
    font: 400 36px/1.5 $roboto;
    color: $dark-font;
    margin-bottom: 20px;
}

.user-search {
    width: 300px;

    &__input {
        width: 100%;
        height: 30px;
        border: none;
        border-bottom: 1px solid #9e9e9e;
        color: #9e9e9e;
        font: 400 16px/30px $roboto;
        padding: 0 5px;
        color: $dark-font;

        &:placeholder {
            color: #9e9e9e;
        }

        &:focus {
            border-bottom: 1px solid blue;
        }
    }

    label {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    span {
        padding: 5px;
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.2s;
        transition-timing-function: ease;
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        opacity: 0.5;
        color: $dark-font;
        font: 400 16px $roboto;
    }

    input:focus+span,
    input:not(:placeholder-shown)+span {
        opacity: 1;
        transform: translateY(-100%) translateX(-10px);
        font-size: 13px;
    }

    input:focus+span,
    input:not(:-ms-input-placeholder)+span {
        opacity: 1;
        transform: translateY(-100%) translateX(-10px);
        font-size: 13px;
    }
}

.input-wrap {
    margin-bottom: 20px;
}

.users-info {
    padding-top: 46px;
}

.users-info-block {
    display: flex;
    width: 350px;
    margin-bottom: 30px;

    &__title {
        font: 400 16px/1 $roboto;
        color: $dark-font;
    }

    &__value {
        font: 400 16px/1 $roboto;
        color: $dark-font;
        margin-left: auto;
    }
}

.user-search-result-wrap {
    margin-top: 40px;
}

.user-search-result-head {
    height: 30px;
    display: flex;
    border-bottom: 1px solid $dark-font;
    margin-bottom: 5px;

    .col {
        width: 33.33%;
        font: 400 14px/8px $roboto;
        color: #9e9e9e;
    }
}

.search-result-wrap {
    max-height: 280px;
    overflow-y: auto;
}

.user-search-result {
    height: 58px;
    display: flex;
    border-bottom: 1px solid #eee;
    cursor: pointer;

    &:hover {
        background: lighten(#eeeeee, 4%);
    }

    &.active {
        background: #eeeeee;
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 10px;
            background: $admin-blue;
        }
    }

    &.blocked {
        background-color: rgba(181, 47, 31, 0.80)
    }


    .col {
        width: 33.33%;
        font: 400 16px/40px $roboto;
        color: $dark-font;
    }
}

.user-edit {
    .row {
        margin-bottom: 20px;
    }

    .col {
        width: 300px;
        margin-right: 30px;
    }

    &__input {
        width: 300px;
        height: 30px;
        border: none;
        border-bottom: 1px solid #9e9e9e;
        color: #9e9e9e;
        font: 400 16px/30px $roboto;
        padding: 0 5px;
        color: $dark-font;

        &:placeholder {
            color: #9e9e9e;
        }

        &:focus {
            border-bottom: 1px solid blue;
        }
    }

    label {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    span {
        padding: 5px;
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.2s;
        transition-timing-function: ease;
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        opacity: 0.5;
        color: $dark-font;
        font: 400 16px $roboto;
    }

    input:focus+span,
    input:not(:placeholder-shown)+span {
        opacity: 1;
        transform: translateY(-100%) translateX(-10px);
        font: 300 13px $roboto;
    }

    input:focus+span,
    input:not(:-ms-input-placeholder)+span {
        opacity: 1;
        font: 300 13px $roboto;
        transform: translateY(-100%) translateX(-10px);
    }
}

.user-edit-save {
    display: inline-block;
    height: 30px;
    padding: 0 15px;
    color: #fff;
    font: 400 14px/30px $roboto;
    background: $admin-blue;
    border: none;
    cursor: pointer;

    &:hover {
        background: lighten($admin-blue, 10%)
    }

    &:disabled {
        background: gray;
        cursor: not-allowed;
    }
}

.user-btn-download,
.user-btn-edit {
    margin-top: 30px;

    button {
        display: block;
        height: 30px;
        padding: 0 15px;
        color: #fff;
        font: 400 14px/30px $roboto;
        background: $admin-blue;
        border: none;
        cursor: pointer;
        margin: 30px 0;

        &:hover {
            background: lighten($admin-blue, 10%)
        }
    }
}

.users-select {
    width: 300px;
}

.users-select {
    label {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    span {
        padding: 5px;
        pointer-events: none;
        position: absolute;
        left: 0;
        top: -42px;
        transition: 0.2s;
        transition-timing-function: ease;
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        opacity: 0.5;
        color: $dark-font;
        font: 400 16px $roboto;
    }
}

.users-select .vs--open+label span,
.users-select .active+label span,
input:not(:placeholder-shown)+span {
    opacity: 1;
    transform: translateY(-100%) translateX(-10px);
    font-size: 13px
}

.users-select .vs--open+label span,
.users-select .active+label span,
input:not(:-ms-input-placeholder)+span {
    opacity: 1;
    transform: translateY(-100%) translateX(-10px);
    font-size: 13px;
}

.modal {
    background: #fff;
    box-shadow: 0 4px 8px 1px rgba(0, 0, 0, .2);
    padding: 15px 50px 25px;
    position: fixed;
    display: block;
    width: 750px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;

    &__title {
        font: 500 20px/1.5 $roboto;
        color: $dark-font;
        text-align: center;
        margin-bottom: 20px;
    }

    &__text {
        font: 400 16px/1.5 $roboto;
        color: $dark-font;
        text-align: center;
    }
}

.modal-btns {
    display: flex;
    margin-top: 20px;

    .decline {
        margin-left: auto
    }

    .remove {
        margin-right: auto
    }
}

.modal-btn {
    display: block;
    height: 40px;
    padding: 0 30px;
    color: #fff;
    font: 400 16px/40px "Roboto", sans-serif;
    background: #1867C0;
    border: none;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
        background: lighten($admin-blue, 10%)
    }
}

#confirmLine {
    color: green;
}

#unconfirmLine {
    color: red;
}

#confirmLine,
#unconfirmLine {
    display: block;
    margin-left: 30px;
    font-size: 18px;
}
</style>
