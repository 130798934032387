export default function responseHandler(err) {
    return new Promise((resolve, reject) => {
        if (typeof err.response !== "undefined" &&
            typeof err.response.status !== "undefined" &&
            err.response.status == 401) {
            localStorage.removeItem('jwt')
            window.location.href = "/";
            return reject();
        }

        return reject(err);
    });
}