import Vue from 'vue'

export default {
  state: {
    basket: {
      purchases: {
        goods: [],
        customGoods: [],
        totalPrice: 0
      }
    },
  },

  mutations: { 
    SET_BASKET(state, payload) {
      state.basket = payload
    }
  },

  actions: {
    fetchBasket({ commit}) {
      Vue.http.get( 
        `/api/me/basket`
      )
      .then(response => { 
        commit('SET_BASKET', response.data)
      })
      .catch(error => {
        console.log( error );
      });
    },

    upsertBasket({dispatch, state }, { order, custom, comment }){

      let cleanObject = (obj) => {
        for (var propName in obj) { 
          if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
          }
        }
      }

      let purchase = (goodsObj, newItem) => {
        let listPurchases = {}
        goodsObj.forEach(good => {
          listPurchases[good.id] = good.count
        });
        // Обьединяем заказы из state с добавленными
        goodsObj = {
          ...listPurchases,
          ...newItem
        }
        //Удаляем заказ со значением null
        cleanObject(goodsObj)
        return goodsObj
      }

      //Получаем список обьектов заказов из state
      let goods = state.basket.purchases.goods
      let customGoods = state.basket.purchases.customGoods

      let bodyParameters = {
        purchases: { 
          goods: {
            ...purchase(goods, custom ? {} : order)
          },
          customGoods: {
            ...purchase(customGoods, custom ? order : {})
          }
        }
      }

      if (comment) {
        bodyParameters.comment = comment
      }
      
      Vue.http.post( 
        `/api/me/basket`,
        bodyParameters
      )
      .then(() => { 
        dispatch('fetchBasket')
      })
      .catch(error => {
        console.log( error );
      });
    },

    makeOrder({dispatch}) {
      return Vue.http.post(
        `/api/order`
      )
      .then(() => {
        dispatch('fetchBasket')
      })
      .catch(error => {
        throw error
      });
    }

  },

  getters: {
    getAmountOfProducts( state ) {
      return state.basket.purchases.goods.length + state.basket.purchases.customGoods.length
    }
  }  
}