<template>
    <div v-if="dadataSuggestions && dadataSuggestions.suggestions.length > 0" class="suggestions-container">
        <div class="suggestions">
            <div v-for="(suggestion, index) in dadataSuggestions.suggestions"
                 v-bind:key="index"
                 class="suggestion"
                 v-on:click="$emit('set-value', suggestion)"
            >
                {{ value(suggestion) }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dataPart: null,
        bound: null,
        query: null
    },
    data() {
        return {
            dadataSuggestions: null
        };
    },
    watch: {
        query() {
            let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
            let token = "d7a0e637cbc3e82bcc0e2cf84a84847a5227be50";

            let options = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + token
                },
                body: JSON.stringify({
                    query: this.query,
                    from_bound: {
                        value: this.bound
                    },
                    to_bound: {
                        value: this.bound
                    },
                })
            }

            fetch(url, options)
                .then(response => response.json())
                .then(data => this.dadataSuggestions = data)
                .catch(error => console.log("error", error));
        }
    },
    methods: {
        value(suggestion) {
            if (this.dataPart === 'house') {
                return [
                    suggestion.data[this.dataPart],
                    suggestion.data.block_type,
                    suggestion.data.block
                ].join(' ').trim();
            }

            return suggestion.data[this.dataPart];
        }
    }
}
</script>

<style lang="scss" scoped>
    .suggestions-container {
        position: relative;
    }

    .suggestions {
        position: absolute;
        width: 100%;
        max-height: 300px;
        overflow-y: scroll;
        font: 400 16px/30px $roboto;
        border: 1px solid #FF9C43;
        border-top: 1px solid transparent;
        background: #1A1C1C;
        z-index: 101;
    }

    .suggestion {
        color: white;
        padding: 5px 10px;
        cursor: pointer;
        text-transform: uppercase;

        &:hover {
            background: #3f3f3f;
        }
    }
</style>