import Vue from 'vue'
import App from './App.vue'
import router from './services/router/index'
import store from './store'
import ApiPlugin from './services/api/ApiPlugin';
import './assets/styles/index.scss'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMask from 'v-mask'
import vSelect from 'vue-select'
import VeeValidate from 'vee-validate';
import VueYandexMetrika from 'vue-yandex-metrika'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 5,
  newestOnTop: true,
  position: "top-right",
   toastClassName: 'custom-toast-container',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});


Vue.use(VeeValidate, {
  locale: 'ru',
});

Vue.component('v-select', vSelect)
Vue.use(VueMask)

Vue.use(require('vue-moment'));


Vue.use(ApiPlugin);

Vue.use(VueAxios, axios)

import VueMasonry from 'vue-masonry-css'

Vue.use(VueMasonry);

Vue.config.productionTip = false

export const EventBus = new Vue();

Vue.use(VueYandexMetrika, {
  id: 54918802,
  router: router,
  env: process.env.VUE_APP_analytics,
  debug: process.env.VUE_APP_analytics == 'development',
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
