<template>
  <div class="admin">
    <div class="admin-container">
      <div class="admin-header">
        <div class="admin-container">
          <div class="admin-header-top">
            <router-link to="/" class="admin-header-link">megabonus.fitauto.ru</router-link>
            <ul class="admin-header-nav">
              <li class="admin-header-nav__item"><router-link to="/admin/users">Пользователи</router-link></li>
              <li class="admin-header-nav__item"><router-link to="/admin/orders">Заказы</router-link></li>
              <li class="admin-header-nav__item"><router-link to="/admin/catalog">Каталог</router-link></li>
              <li class="admin-header-nav__item"><router-link to="/admin/tasks">Задания</router-link></li>
              <li class="admin-header-nav__item"><router-link to="/admin/access">Доступ</router-link></li>
              <li class="admin-header-nav__item"><router-link to="/admin/messages">Cообщения</router-link></li>
            </ul>
            <div class="burger-ico" @click="toggleMenu">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="dropdown" :class="{active: isActive}">
            <div class="dropdown-item" @click="logout">
              <div class="dropdown-item__icon logout"></div>
              <div class="dropdown-item__name">Выйти</div>
            </div>
          </div>
          <div class="admin-header-bot"></div>
        </div>
      </div>
      <div class="admin-main">
      <router-view></router-view>
    </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isActive: false
      }
    },

    methods: {
      toggleMenu() {
        this.isActive = !this.isActive
      },

      logout() {
        localStorage.removeItem('jwt-admin')
        this.$router.go()
      }
    },
  }
</script>

<style lang="scss" scoped>
  .admin {
    background: #fff;
    min-height: 100vh
  }

  .admin-container {
    min-width: 1160px;
    padding: 0 20px;
  }

  .admin-header {
    position: fixed;
    height: 80px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);
    left: 0;
    right: 0;
    background: #fff;
    z-index: 100;
  }

  .admin-main {
    padding-top: 100px;
    height: 1000px;
  }

  .admin-header-top {
    height: 72px;
    display: flex;
    align-items: center;
    position: relative;
  }

  .admin-header-link {
    font: 500 18px/1 $roboto;
    color: $dark-font;
    line-height: 22px;
    border-bottom: none;
  }

  .admin-header-nav {
    margin-left: auto;
    margin-right: 35px;
    display: flex;
    align-items: center;

    &__item {
      font: 500 16px/1 $roboto;
      color: $dark-font;
      margin-right: 20px;
      &:last-child {
        margin-right: 0px;
      }
      & a {
        color: $dark-font;
        border-bottom: 2px solid transparent
      }
    }
  }

  .admin-header-nav .admin-header-nav__item .router-link-active {
    border-bottom: 2px solid $admin-blue;
  }

  .burger-ico {
    cursor: pointer;
    & > div {
      width: 25px;
      height: 3px;
      background-color: black;
      margin: 4px 0;
    }
  }

  .dropdown {
    display: none;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 180px;
    height: 42px;
    right: 20px;
    top: 60px;
    box-shadow: 0 4px 8px 1px rgba(0,0,0,.2);
    border-radius: 3px;
    z-index: 1000;
    background: #fff;
    z-index: 110;
  }

  .dropdown.active {
    display: flex;
  }

  .dropdown-item {
    display: flex;
    margin: 5 0px;
    padding: 10px 0;
    cursor: pointer;
    
    &:hover {
      background: lighten(#ccc, 7%)
    }

    &__icon {
      width: 16px;
      height: 16px;
      margin-right: 16px;
      margin-left:20px;
    }

    &__name {
      font: 400 16px/1 $roboto;
      color: $dark-font;
    }
  }

  .publish {
    background-image: url('../assets/images/icons/icon-publish.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .logout {
    background-image: url('../assets/images/icons/icon-logout.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

</style>
