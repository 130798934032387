/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import products from './modules/products'
import basket from './modules/basket'
import profile from './modules/profile'
import tasks from './modules/tasks'
import history from './modules/history'
import search from './modules/search'

import users from './modules/admin/users'
import orders from './modules/admin/orders'
import authAdmin from './modules/admin/authAdmin'
import catalog from './modules/admin/catalog'
import messages from './modules/messages'
import adminMessages from './modules/admin/adminMessages'
import modal from './modules/modal'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
      auth,
      basket,
      products,
      profile,
      tasks,
      history, 
      search,
      modal,

      // админка
      users,
      orders,
      authAdmin,
      catalog,
      adminMessages,
      messages
    }
})
