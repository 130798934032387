<template>
    <div class="modal" v-if="orderUpdatePopup">
        <div class="category-popup">
            <div class="modal__title">Вы действительно хотите изменить статус заказа №{{ orderId }}?</div>
            <div class="modal__text">После этого изменение статуса заказа будет невозможно.</div>
            <div class="modal-btns">
                <button class="modal-btn delete" @click="confirmUpdate">
                    Изменить
                </button>
                <button class="modal-btn decline" @click="declineOrder">
                    Отмена
                </button>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * @vue/component
 * @name OrderPopup
 * @description Всплывающее окно с подтверждением изменения статуса
 */

export default {
    name: "OrderPopup",
    props: {
        orderUpdatePopup: {
            type: Boolean,
            required: true
        },
        orderId: {
            type: Number,
            required: true
        },
        updateOrder: {
            type: Function,
            required: true
        }
    },
    methods: {
        declineOrder() {
            this.$emit("popupIsActive", false); 
        },
        confirmUpdate() {
            this.updateOrder(); 
            this.$emit("popupIsActive", false);
        }
    }
}
</script>

<style lang="scss" scoped>
.modal {
    background: $white;
    box-shadow: 0 4px 8px 1px rgba(0, 0, 0, .2);
    padding: 15px 50px 25px;
    position: fixed;
    display: block;
    width: 750px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;

    &__title {
        font: 500 20px/1.5 $roboto;
        color: $dark-font;
        text-align: center;
        margin-bottom: 20px;
    }

    &__text {
        font: 400 16px/1.5 $roboto;
        color: $dark-font;
        text-align: center;
    }

    &-btns {
        display: flex;
        margin-top: 20px;

        .decline {
            margin-left: auto;
        }

        .remove {
            margin-right: auto;
        }
    }

    &-btn {
        display: block;
        height: 40px;
        padding: 0 30px;
        color: $white;
        font: 400 16px/40px "Roboto", sans-serif;
        background: $admin-blue;
        border: none;
        cursor: pointer;
        margin-top: 20px;

        &:hover {
            background: lighten($admin-blue, 10%);
        }
    }
}
</style>
