/**
* Разбивает массив на более мелкие чанки указанного размера.
*
* @param {Array} myArray - Массив, который нужно разбить.
* @param {number} chunk_size - Размер каждого чанка.
* @return {Array} Массив чанков меньшего размера.
*/

const chunkArray =  (myArray, chunk_size) => {
  let index = 0;
  let arrayLength = myArray.length;
  let tempArray = [];
  let myChunk = []
  for (index = 0; index < arrayLength; index += chunk_size) {
    myChunk = myArray.slice(index, index+chunk_size);
    tempArray.push(myChunk);
  }
  return tempArray 
}

export default chunkArray