<template>
  <div class="children" :class="{'active': activeId === subMenu.id}">
    <div
        class="children__item"
        v-for="(child, childId) in subMenu.children" :key="childId"
        :style="'margin-left: ' + 4 * child.countParents + 'px;'"
    >
      <label
          v-if="child.children.length > 0"
          class="children__item__control"
          :class="{'active': openId === child.id}"
          @click="showChildren(child.id)">&#10148;
      </label>

      <a @click="closeSideMenu(child)">{{ child.name }}</a>

      <side-sub-menu
          v-if="child.children.length > 0"
          :subMenu="child"
          :activeId="openId"
      />
    </div>
  </div>
</template>

<script>
import { EventBus } from '/src/main';

export default {
  name: 'SideSubMenu',
  props: {
    subMenu: [],
    activeId: {
      required: true
    }
  },
  data() {
    return {
      isOpen: null,
      openId: -1
    }
  },

  methods: {
    showChildren(id) {
      //  отсеживание изменений по выбранным категориям
      if (this.openId === id) {
        this.openId = -1
        return
      }
      this.openId = id
    },

    closeSideMenu(category) {
      EventBus.$emit('menuClicked', {"menuClicked": 'catalog', "isOpen": false} );
      this.$router.push({name: 'products', params:{id: category.id}})
    },
  }
}
</script>

<style lang="scss" scoped>
.children {
  display: none;

  &.active {
    display: block;
    padding-top: 5px;
    text-align: left;
  }

  &__item {
    padding: 3px;

    a {
      font: 400 14px/1 $roboto;
      color: $grey-font;
      transition: color .2s;
      cursor: pointer;

      &:hover {
        color: #fff;
      }
    }

    &__control {
      transform: rotate(0deg);
      cursor: pointer;
      position: absolute;
      margin-left: -15px;

      &.active {
        transform: rotate(270deg);
        color: #ffffff;
      }
    }
  }
}
</style>
