<template>
  <div :class="{isOpen: isOpen}" class="side-nav-wrap">
    <div class="side-nav-inner">
      <span @click="closeSideMenu" class="menu-close"></span>
      <ul v-if="menuClicked === 'catalog'" class=side-nav>
        <li v-for="(link, idx) in categoriesMain" :key="idx" class="side-nav__item">
          <label
              v-if="link.children.length > 0"
              class="side-nav__control"
              :class="{'active': openId === link.id}"
              @click="showChildren(link.id)"
              :for="link.id">&#10148;
          </label>
          <router-link :id="link.id" v-on:click.native="closeSideMenu" :to="{name: 'products', params:{id: link.id}}">
            {{ link.name }}
          </router-link>
          <side-sub-menu
              v-if="link.children.length > 0"
              :activeId="openId"
              :subMenu="link"
          />
        </li>

        <li v-for="(customCategory, idx) in customCategories" :key="idx" class="side-nav__item">
          <router-link v-on:click.native="closeSideMenu" :to="{name: 'products-custom', params:{id: customCategory.id}}">{{ customCategory.name }}</router-link>
        </li>
      </ul>
      <ul v-if="menuClicked === 'cabinet'" class="side-nav">
        <li class="side-nav__item"><router-link v-on:click.native="closeSideMenu" to="/profile">профиль</router-link></li>
        <li class="side-nav__item"> <router-link v-on:click.native="closeSideMenu" to="/history">история</router-link></li>
        <li class="side-nav__item"> <span @click="logout">Выйти</span></li>
      </ul>
    </div>
  </div>
</template>
<script>
import { EventBus } from '@/main.js';
import SideSubMenu from "./SideSubMenu";
import { mapState } from 'vuex'
export default {
  components: {
    SideSubMenu
  },
  data() {
    return {
      isOpen: null,
      openId: -1,
      menuClicked: "",
      cabinetMenu: [
        {
          "title": "профиль",
          "path": "/profile"
        },
        {
          "title": "история",
          "path": "/history"
        },
        {
          "title": "выйти",
          "path": "#"
        },

      ]
    }
  },
  computed: {
    ...mapState({
      categories: state => state.products.categories,
      categoriesMain: state => state.products.categoriesMain,
      customCategories: state => state.products.customCategories
    })
  },

  methods: {
    closeSideMenu() {
      this.isOpen = false
    },
    showChildren(id) {
      //  отсеживание изменений по выбранным категориям
      if (this.openId === id) {
        this.openId = -1
        return
      }
      this.openId = id
    },

    logout() {
      localStorage.removeItem('jwt')
      this.$router.go()
    }
  },

  created() {
    EventBus.$on('menuClicked', params => {
      this.menuClicked = params.menuClicked
      this.isOpen  = params.isOpen
    });
    this.$store.dispatch('fetchCategories')
    this.$store.dispatch('fetchCustomCategories')
  }
}
</script>

<style lang="scss" scoped>
  .side-nav-wrap {
    overflow-y: auto;
    overflow-x: overlay;
    display: block;
    width: 340px;
    position: absolute;
    top:0;
    left:-340px;
    bottom: 0;
    background: #1A1C1C;
    color: $grey-font;
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
    transition: left .3s;
    z-index: 10;
  }

  .side-nav-wrap.isOpen {
    left: 0px;
  }

  .side-nav-inner {
    position: relative;
  }

  .menu-close {
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    right: 15px;
    top: 15px;
    background-image: url('../assets/images/icons/close-menu.svg');
    cursor: pointer;
  }

  .side-nav {
    padding-left: 30px;
    padding-top: 50px;

    &__control {
      transform: rotate(0deg);
      cursor: pointer;
      position: absolute;
      margin-left: -15px;

      &.active {
        transform: rotate(270deg);
        color: #ffffff;
      }
    }

    &__item {
      margin-bottom: 26px;
      //text-align: center;

      a, span {
        font: 400 14px/1 $roboto;
        color: $grey-font;
        text-transform: uppercase;
        transition: color .2s;
        cursor: pointer;
        &:hover {
          color: #fff;
        }
      }
    }
  }

  .side-menu {
    display: block;
    position: absolute;
    top:0;
    left:0;
    height: 200px;
    width: 200px;
    background: #ccc;
    color: #000;
  }
</style>
