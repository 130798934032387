<template>
  <div :class="{open: isOpen}" class="task-item-wrap noSelect" @click="toggleOpen">
    <div :class="{complete: isTaskComplete}" class="task-item" >
      <div class="task-item__top">
        <div class="task-item__img">
          <img :src="item.logo_url" alt="">
        </div>
        <div class="task-item-top-right">
          <div class="task-item__from">Задание от {{ item.name }}</div>
          <div class="task-period">Действует: <span class="task-period__days">{{ countDays }}</span></div>
        </div>
      </div>
      <div class="inner" :class="{ open: isOpen }">
        <div class="task-item__md" @click.stop="$refs.link.click()">
          <div class="task-description" v-html="description"></div>
          <div class="task-pdf" v-if="item.order_document_url">
            <span class="task-pdf__ico"></span>
            <a ref="link" :href="item.order_document_url" target="_blank" style="visibility: none;"></a>
            <div class="task-pdf__txt">{{ item.order_document_name }}</div>
          </div>
          <div v-if="youtube" class="video">
            <iframe width="100%" height="100%" 
              :src="youtube"
              frameborder="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen="">
            </iframe>
          </div>
        </div>
        <div class="task-item__bot">
          <div class="tasks-price">
            {{ item.fee }}
            <span class="tasks-price__ico"></span>
          </div>
          <span class="task-arrow" :class="{ open: isOpen } "></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import moment from 'moment'
  export default {
    props: ['item'],

    data() {
      return {
        isOpen: false,
      }
    },

    computed: {
      ...mapState({
        userRoles: state => state.tasks.userRoles,
      }),

      isTaskComplete() {
        const today = this.toTimestamp(moment().format('YYYY-MM-DD'))
        if(this.toTimestamp(this.item.endDate) < today) {
          return true
        }
        return false
      },

      youtube() {
        return this.item.video_link ? this.item.video_link /*"https://www.youtube.com/embed/" + this.item.video_link.match(/(\w)+$/)[0]*/ : ''
      },

      forRole() {
        return this.userRoles.find(x => x.id === this.item.forRoleId).name
      },
      
      description() {
        return this.item.description ? this.item.description.replace(/\n/g, "<br/>") : '';
      },

      countDays() {
        if(this.item.sortValue === 10) {
          let dateEnd = moment(this.item.endDate).add(1, 'days')
          let dateNow = moment()
          let days = dateEnd.diff(dateNow, 'days') + 1

          return this.dayGrammar(days)
        }
        if(this.item.sortValue === 7) {
          return "Бессрочно"
        }
        if(this.item.sortValue === 5) {
          return `c ${this.formatDate(this.item.startDate)}`
        }
        if(this.item.sortValue === 0) {
          return "Завершено"
        }
        return ""
      }
    },

    methods: {

      dayGrammar(days) {
        let lastDigit = days % 10;
        if (lastDigit == 1) 
          return `${days} день`
        if (lastDigit > 1 && lastDigit < 5)
          return `${days} дня`
        return `${days} дней`
      },

      created() {
        this.moment.lang('ru');
      },

      toggleOpen() {
        this.isOpen = !this.isOpen
      },

      toTimestamp(strDate){
        let datum = Date.parse(strDate)
        return datum/1000;
      },

      formatDate(date) {
        let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [day, month, year].join('.');
      },
    }
  }
</script>

<style lang="scss" scoped>

.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

  .task-item-wrap {
    position: relative;
  }

  .task-item {
    width: 100%;
    padding: 15px 0px 0px;
    background: #232525;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
    margin-bottom: 20px;
    display: block;

    &__top {
      display: flex;
      padding: 0 10px 10px 0;
      position: relative;
      align-items: center;
    }

    &__md {
      padding-top: 15px;
      background: #232525;
      display: none;
    }

    &__img {
      width: 105px;
      max-height: 70px;
      padding-right: 15px;
      padding-left: 10px;
      align-self: center;
      display: flex;
      justify-content: center;
      
      img {
        max-width: 80px;
        max-height: 70px;
      }
    }
    
    &__from {
      font: 400 18px/21px $roboto;
      color: #C4C4C4;
      height: 65px;
      overflow: hidden;

      span {
        display: block;
      }
    }

    &__to {
      font: 400 13px/16px $roboto;
      color: #C4C4C4;
      margin-top: 5px;

      span {
        display: block;
      }
    }

    &__bot {
      display: flex;
      align-items: center;
      padding: 0 10px;
      background: #232525;
      position: relative;
      height: 60px;
      align-items: flex-start;
      margin-top: 10px;
    }
  }

  .inner {
    position: relative;
    display: block;
  }
  .inner.open {
    height: 100%;
    bottom: -100%;
    
    .task-item__md {
      display: block;
    }
  }

  .task-item.open {
    z-index: 100000;
  }
  
  .tasks-price {
    font: 400 24px/1 $roboto;
    color: #FFFEFA;
    display: flex;
    align-items: flex-end;
    margin-left: auto;

    &__ico {
      width: 20px;
      height: 18px;
      display: block;
      background-image: url('../assets/images/icons/coins.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 5px;
      margin-bottom: 2px;
    }
  }

  .task-arrow {
    display: block;
    position: absolute;
    width: 18px;
    height: 8px;
    background-image: url('../assets/images/icons/arrow-down-grey.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    left: 50%;
    margin-left: -9px;
    bottom: 15px;
  }

  .task-arrow.open {
    transform: rotate( 180deg );
  }

  .task-description {
    font: 400 14px/16px $roboto;
    color: #C4C4C4;
    padding: 0 10px;
  }

  .task-pdf {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 0 10px;

    &__ico {
      display: block;
      width: 20px;
      height: 25px;
      background-image: url('../assets/images/icons/icon-pdf.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 10px;
    }

    &__txt {
      font: 400 14px/16px $roboto;
      color: #FFFEFA;
    }
  }

  .video {
    margin-top: 20px;
  }

  .task-item__md.open {
    display: block;
  }

  .task-period {
    font: 300 14px/16px $roboto;
    color: #FFFEFA;
    margin-top: 10px;

    &__days {
      color: #ff9c43;
    }
  }

  .task-item.complete {
    filter: grayscale(1);
  }
</style>
