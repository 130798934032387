<template>
  <div class="admin-messages-form">
    <div class="admin-messages-head">
      <h2 class="admin-messages-heading-form">Просмотр сообщения</h2>
      <div class="messages-form-list-btns">
        <button
          class="messages-form-list-dbtn add"
          disabled="message.status === 'sended'"
        >
          <h3>Удалить</h3>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="message-wrap">
          <span class="message-wrap-title">Дата публикации</span>
          <p class="message-wrap-value">
            {{ dateFormatter(message.createdAt) }}
          </p>
        </div>

        <div class="message-wrap">
          <span class="message-wrap-title">Заголовок</span>
          <p class="message-wrap-value">{{ message.title }}</p>
        </div>

        <div class="message-wrap">
          <span class="message-wrap-title">Кому</span>
          <p class="message-wrap-value">
            {{ groupsFormatter(message.groups) }}
          </p>
        </div>

        <div class="message-wrap">
          <span class="message-wrap-title">Краткое описание</span>
          <div class="short-description">
               <p class="message-wrap-value">{{ message.shortDescription }}</p>
          </div>
        </div>

        <div class="message-wrap">
          <span class="message-wrap-title">Текст сообщения</span>
          <p class="message-wrap-value text">{{ message.text }}</p>
        </div>
      </div>
      <div class="col">
        <div class="message-wrap">
          <span class="message-wrap-title">Заголовок ссылки</span>
          <p class="message-wrap-value">{{ message.titleLink }}</p>
        </div>
        <div class="message-wrap">
          <span class="message-wrap-title">Ссылка</span>
          <p class="message-wrap-value">{{ message.link }}</p>
        </div>
        <div class="message-wrap-logo" v-if="message.icon">
          <span class="message-wrap-title">Обложка</span>
          <img class="message-wrap-img" :src="message.icon" alt="Обложка" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  methods: {
    dateFormatter(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    groupsFormatter(groups) {
      const groupsToRussianMapping = {
        executor: "Исполнителю",
        manager: "Руководителю",
        masterInspector: "Мастеру-приемщику",
      };

      const formattedGroups = groups
        ? groups.map((group) => groupsToRussianMapping[group]).join(", ")
        : "-";

      return formattedGroups;
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  word-break: break-all;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.message-wrap {
  margin-bottom: 25px;
  &-title {
    font: 400 14px/1.6 $roboto;
    opacity: 0.6;
  }
  &-value {
    font: 400 14px/1.6 $roboto;
    opacity: 0.8;
  }
  &-logo {
    display: flex;
    flex-direction: column;
  }
  &-img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 7px;
  }
}
.short-description {
  word-wrap: break-word;
  word-break: break-all;
}
.messages-form-list-btns {
  display: flex;
  gap: 10px;
  margin-top: 20px;

  .add {
    margin-right: auto;
  }

  .remove,
  .save {
    margin-left: auto;
  }

  .messages-form-list-btn {
    display: block;
    height: 40px;
    padding: 0 15px;
    color: $white;
    font: 400 14px/8px $roboto, sans-serif;
    background: $admin-blue;
    border: none;
    cursor: pointer;

    &:hover {
      background: lighten($admin-blue, 10%);
    }
  }

  .messages-form-list-dbtn {
    display: block;
    height: 40px;
    padding: 0 15px;
    color: $white;
    font: 400 14px/8px $roboto, sans-serif;
    background: $admin-red;
    border: none;
    cursor: pointer;

    &:disabled {
      pointer-events: none;
      cursor: not-allowed;
      background: lighten($admin-red, 30%);
    }

    &:hover {
      background: lighten($admin-red, 10%);
    }
  }
}

.admin-messages-form {
  margin-top: 30px;

  .col {
    width: 33.33%;
  }
}

.admin-messages-head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
}

.admin-messages-heading {
  margin-top: 30px;
  margin-bottom: 10px;
  font: 700 32px/1.5 $roboto;
  color: $dark-font;
}

.admin-messages-heading-form {
  margin-top: 30px;
  margin-bottom: 10px;
  font: 400 24px/1.5 $roboto;
  color: $dark-font;
}
</style>

