<template>
    <div class="user-order">
        <div class="user-order__item">
            <div class="user-order__name">ФИО</div>
            <div class="user-order__value">{{ userData.full_name }}</div>
        </div>
        <div class="user-order__item">
            <div class="user-order__name">Гражданство</div>
            <div class="user-order__value" v-if="userData.country === 'ru'">Российская Федерация</div>
            <div class="user-order__value" v-if="userData.country === 'kz'">Казахстан</div>
        </div>
        <div class="user-order__item">
            <div class="user-order__name">Телефон</div>
            <div class="user-order__value">{{ convert(userData.phone) }}</div>
        </div>
        <div class="user-order__item">
            <div class="user-order__name">Место работы</div>
            <div class="user-order__value" v-if="userData.station">{{ userData.station.address }}</div>
        </div>
        <div class="user-order__item">
            <div class="user-order__name">Паспорт/Серия</div>
            <div class="user-order__value">{{ userData.passport_serie }}</div>
        </div>
        <div class="user-order__item">
            <div class="user-order__name">Паспорт/номер</div>
            <div class="user-order__value">{{ userData.passport_number }}</div>
        </div>
    </div>
</template>

<script>
/**
 * @vue/component
 * @name UserOrder
 * @description
 * Выводит информацию о заказе пользователя
 */

import convertPhone from '@/utils/convertPhone';

export default {

    name: "UserOrder",

    props: {
        userData: {
            type: Object,
            required: true
        },
    },
    methods: {
        convert(num) {
            return convertPhone(num)
        }
    }
}
</script>

<style lang="scss" scoped>
.user-order {
    margin-top: 40px;
    margin-bottom: 40px;

    &__item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    &__name {
        font: 400 14px/17px $roboto;
        color: $dark-font;
        width: 200px; 
        opacity: 0.6;
    }

    &__value {
        font: 400 14px/17px $roboto;
        color: $dark-font;
        flex-grow: 1;
    }
}
</style>