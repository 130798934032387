import Vue from 'vue'

export default {
  state: {
    users: [],
    user: null,
    stats: {},
    usersDataToDownload: null
  },

  mutations: { 
    SET_SEARCH_USERS(state, payload) {
      state.users = payload
    },

    SET_SEARCH_USERSCONCAT(state, payload) {
      let concat = state.users.concat(payload)
      state.users = concat
    },

    SET_USER_BY_ID(state, payload) {
      state.user = payload
    },

    SET_STATS(state, payload) {
      state.stats = payload
    },

    CLEAR_USER(state) {
      state.user = null
    },

    SET_USERS_DATA_TO_DOWNLOAD(state, payload) {
      state.usersDataToDownload = payload
      setTimeout(() => {
        state.usersDataToDownload = null
      }, 200);
    },
  },

  actions: {
    searchUsers({ commit}, { name, phone, status, offset}) {
      status = status || parseInt(status) === 0 ? status : '';
      Vue.http_admin.get( 
        "api/users?full_name='" + name + "'" +
          "&phone='" + phone + "'" +
          "&verified=" + status +
          "&offset=" + offset + "&limit=10"
      )

      .then(response => {
        if(offset === 0) {
          commit('SET_SEARCH_USERS', response.data)
  
        } else {
          commit('SET_SEARCH_USERSCONCAT', response.data)
        }
      })

      .catch(error => {
        console.log( error );
      });
    },

    getUserById({ commit }, id) {
      Vue.http_admin.get( 
        `api/user/${id}`
      )

      .then(response => {
        commit('SET_USER_BY_ID', response.data)
      })

      .catch(error => {
        console.log( error );
      });
    },

    clearUser({ commit }){
      commit('CLEAR_USER')
    },

    getStats({ commit }) {
      Vue.http_admin.get(
        `api/users/statistics`
      )

      .then(response => {
        commit('SET_STATS', response.data)
      })

      .catch(error => {
        console.log(error);
      });
    },

    blockUser({dispatch}, id) {
      dispatch('changeBlockStatus', {id: id, status: true})
    },

    unBlockUser({dispatch}, id) {
      dispatch('changeBlockStatus', {id: id, status: false})
    },

    changeBlockStatus(state, {id, status}) {
      const bodyParameters = {
        blocked: status
      }

      return Vue.http_admin.put(
          `/api/user/${id}`,
          bodyParameters
        )
        .then(() => {
        })
        .catch(error => {
          console.log(error);
      });
    },

    getUserData({commit}) {
      Vue.http_admin.get(
          `api/users/download-data`, {
            responseType: 'blob'
          }
      )
      .then(response => {
        commit('SET_USERS_DATA_TO_DOWNLOAD', response.data)
      })
      .catch(error => {
        console.log(error);
      });
    },
  }
}