import Vue from 'vue'

export default {
  state: {
    products: null,
  },

  mutations: { 
    SET_SEARCH_PRODUCTS(state, payload) {
      state.products = payload
    }
  },

  actions: {
    fetchSearchProducts({ commit}, { searchValue }) {
      commit('SET_SEARCH_PRODUCTS', null)
      Vue.http.get( 
        "api/goods?limit=10" + (searchValue ? "&name=" + searchValue : '')
      )

      .then(response => { 
        commit('SET_SEARCH_PRODUCTS', response)
      })

      .catch(error => {
        console.log( error );
      });
    }
  }  
}