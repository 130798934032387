<template>
  <div class="bottom-submenu-inner">
    <div class="bottom-submenu-title" @click="submenuClose">Каталог</div>

    <template v-if="catalogMenu.length">
      <slick class="slider-for"
           ref="for"
           :options="slickCatalogOptions"
          >
        <ul  class="bottom-submenu-nav" v-for="(slide, idx) in catalogMenu" :key="idx">
          <li v-for="(link, idx) in slide" :key="idx" class="bottom-submenu-nav__item">
            <router-link :to="{name: 'products', params:{id: link.id}}">{{ link.name }}</router-link>
          </li>
        </ul>
      </slick>

    </template>
  </div>
</template>

<script>
  import Slick from 'vue-slick';
  import 'slick-carousel/slick/slick.css';

  import chunkArray from '@/utils/chankArray'
  import { EventBus } from '@/main.js';
  import { mapState } from 'vuex'

  export default {
    data() {
      return {
        catalogMenu: []
      }
    },

    computed: {
      ...mapState({
        categories: state => state.products.categories
      }),

      slickCatalogOptions() {
        return {
          slidesToShow: 1,
          arrows: true,
          infinite: false,
          autoplay: false
        }
      },
    },

    watch: {
      categories() {
        this.catalogMenu = chunkArray(this.categories, 3)
      }
    },

    methods: {
      submenuClose() {
        EventBus.$emit("submenuClose", {"submenuClose": null});
      },
    },

    created() {
      this.$store.dispatch('fetchCategories')
    },

    components: {
      Slick
    }
  }
</script>
