import localStorage from 'localStorage';
import axios from 'axios'
import errorHandler from '../../errorHandler'
import errorHandlerAdmin from '../../errorHandlerAdmin'

const ApiPlugin = (Vue) => {
  const instance = axios.create({
	baseURL:	process.env.VUE_APP_baseURL
  });
  console.log(instance.baseURL);
  instance.interceptors.request.use(config =>  {

    config.headers["Content-Type"] = 'application/json'

    let token = localStorage.getItem('jwt')

    if (token) config.headers['Authorization'] = `Bearer ${token}`

    return config
  });

  const adminInstance = axios.create({
	baseURL:	process.env.VUE_APP_baseURL
  })

  adminInstance.interceptors.request.use(config => {

    config.headers["Content-Type"] = 'application/json'

    let tokenAdmin = localStorage.getItem('jwt-admin')

    if (tokenAdmin) config.headers['Authorization'] = `Bearer ${tokenAdmin}`

    return config
  });

  instance.interceptors.response.use(null, errorHandler);
  adminInstance.interceptors.response.use(null, errorHandlerAdmin);

  Vue.prototype.$http = Vue.http = instance
  Vue.prototype.$http_admin = Vue.http_admin = adminInstance
};

export default {install: ApiPlugin}
